@import '../../styles/_index.scss';

// Local Variables
$ac-careers-wave-height: 136px; // See svg files for height
$ac-careers-hero-section-height: 400px;
$ac-careers-model-section-height: 312px;
$ac-careers-engineers-section-height: 344px;
$ac-careers-become-partner-form-width: 857px;
$ac-careers-become-partner-form-textarea-height: 224px;
$ac-careers-impact-section-height: 320px;

// Wave container
.ac-careers-wave {
	background-position: 50% 0;
	background-repeat: repeat-x;
	height: $ac-careers-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}



// Content
.ac-careers-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-brand-secondary;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-careers-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

#engineers-heading {
	text-align: left;
}

.ac-careers-text {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
}

// Hero section
.ac-careers-hero {
	background: url('../../assets/waves/wave-hero-purple.svg');
	background-position: 50% -200px;
	background-repeat: repeat-x;
	color: $ac-color-text-secondary;
	min-height: $ac-careers-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
}

.ac-careers-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-careers-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: -25px;
	top: 275px;
	width: $ac-global-space-6xl;
	// z-index: 0;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; 
		width: $ac-global-space-5xl; 
		right: 25px;
		top: 150px;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-4xl; 
		width: $ac-global-space-4xl; 
		right: 125px;
		top: 225px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// jobs section
.ac-careers-jobs-section {
	background: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	min-height: $ac-careers-engineers-section-height;
	padding: $ac-global-space-5xl $ac-global-space-xl $ac-global-space-2xl;
	position: relative;
	width: 100%;
}

.ac-careers-jobs-section-container {
	width: 100%;
	margin: 0 auto;
	// max-width: $ac-global-content-container-width;
	// max-width: 840px;
	position: relative;

	.ac-careers-jobs-content {
		display: grid;
		gap: 125px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		margin: 0 auto;
		max-width: $ac-global-content-container-width;
		position: relative;
		width: 100%;


		@media screen and ($ac-global-breakpoints-md) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			gap: $ac-global-space-2xl;
		}

		.ac-careers-text {
			margin-bottom: 15px;
		}

		.jobLinks {
			font-family: $ac-global-font-family-semibold;
			color: $ac-color-teal-1;
			margin-bottom: 20px;
			text-decoration: none;
		}

		.ac-careers-body {
			margin-bottom: 20px;
		}
	}
}



.ac-careers-jobs-1-star {
	width: $ac-global-space-5xl;
	height: $ac-global-space-5xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: -17%;
	top: -1%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl + 10px; 
		width: $ac-global-space-3xl + 10px; 
		left: -23%;
		top: unset;
		bottom: 25%;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-3xl; 
		width: $ac-global-space-3xl; 
		top: unset;
		bottom: 0% !important;
		right: -17%;
		left: unset;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

.ac-careers-jobs-2-star {
	height: $ac-global-space-3xl + 10px; 
	width: $ac-global-space-3xl + 10px; 
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 0%;
	top: 8%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-4xl; 
		width: $ac-global-space-4xl; 
		right: -20%;
		top: unset;
		bottom: 20%;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

.ac-careers-jobs-3-star {
	height: $ac-global-space-3xl + 10px; 
	width: $ac-global-space-3xl + 10px; 
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: -14%;
	top: 28%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; 
		width: $ac-global-space-5xl; 
		left: -25.5%;
		top: unset;
		bottom: -5%;
		z-index: 2;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}



// moreInfo section
.ac-careers-moreInfo {
	background: $ac-color-gradient-light-reverse;
	display: flex;
	justify-content: center;
	// margin-bottom: -72px;
	min-height: $ac-careers-model-section-height;
	padding: ($ac-careers-wave-height + 40px) $ac-global-space-xl $ac-global-space-5xl;
	position: relative;
	width: 100%;
}

.ac-careers-moreInfo-container {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.ac-careers-moreInfo-content {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	width: 100%;

	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;
	
		@media screen and ($ac-global-breakpoints-md) {
			grid-template-columns: 1fr;
			gap: 72px;
		}
}

.ac-careers-moreInfo-content .moreInfoIcon{
	margin: 0 auto $ac-global-space-2xl;
	font-size: $ac-global-space-4xl;
	color: $ac-color-neutral-3;
}

.ac-careers-subheading.moreInfo {
	text-align: center;
}