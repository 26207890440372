@import '../../styles/_index.scss';

// Local Variables
$ac-caseStudies-wave-height: 136px; // See svg files for height
$ac-caseStudies-hero-section-height: 400px;
$ac-caseStudies-model-section-height: 312px;
$ac-caseStudies-engineers-section-height: 344px;
$ac-caseStudies-become-partner-form-width: 857px;
$ac-caseStudies-become-partner-form-textarea-height: 224px;
$ac-caseStudies-impact-section-height: 320px;

// Wave container
.ac-caseStudies-wave {
	background-position: 50% 0;
	background-repeat: repeat-x;
	height: $ac-caseStudies-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}



// Content
.ac-caseStudies-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-brand-secondary;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-caseStudies-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

#engineers-heading {
	text-align: left;
}

.ac-caseStudies-text {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
}

.ac-caseStudies-text .bold {
	text-decoration: underline;
}

// Hero section
.ac-caseStudies-hero {
	background: url('../../assets/waves/wave-hero-purple.svg');
	background-position: 50% -200px;
	background-repeat: repeat-x;
	color: $ac-color-text-secondary;
	min-height: $ac-caseStudies-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
}

.ac-caseStudies-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-caseStudies-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: -25px;
	top: 275px;
	width: $ac-global-space-6xl;
	// z-index: 0;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; 
		width: $ac-global-space-5xl; 
		right: 25px;
		top: 150px;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-4xl; 
		width: $ac-global-space-4xl; 
		right: 125px;
		top: 225px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Apps section
.ac-admissions-apps-section {
	background: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	padding: $ac-global-space-5xl $ac-global-space-xl $ac-global-space-3xl;
	position: relative;
	width: 100%;

	@media screen and ($ac-global-breakpoints-md) {
		padding-bottom: 50px !important;
	}
}

.ac-caseStudies-apps-section-container {
	width: 100%;
	margin: 0 auto;
	max-width: 920px;


	.ac-caseStudies-text {
		margin-bottom: 20px;
	}
}

.ac-admissions-apps-section .appsDivs {
	// position: relative;
	padding-bottom: 80px;
	border-bottom: 1px solid rgba(148, 148, 148, 0.25);
	margin-bottom: 80px;

	// &:nth-of-type(3) {
	// 	margin-bottom: 0px;
	// 	border: none;
	// 	padding-bottom: 0px;
	// 	margin-bottom: 0px;
	// }
}

.ac-admissions-apps-section .appImages {
	width: 350px;
	height: auto;
	margin-left: 60px;
	background-color: whitesmoke;

	@media screen and ($ac-global-breakpoints-md) {
		margin-left: 0px !important;
		margin-bottom: 40px !important;
	}

	@media screen and ($ac-global-breakpoints-xs) {
		width: 100% !important;
	}
}


.ac-caseStudies-apps-1-star {
	width: $ac-global-space-5xl;
	height: $ac-global-space-5xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: 2%;
	top: 5%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl + 10px; 
		width: $ac-global-space-3xl + 10px; 
		left: 8.5%;
		top: 14.5%;
		// bottom: 45%;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

.ac-caseStudies-apps-2-star {
	height: $ac-global-space-4xl; 
	width: $ac-global-space-4xl; 
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 2%;
	top: 41.5%;
	

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl + 10px; 
		width: $ac-global-space-3xl + 10px; 
		right: 6%;
		top: 54%;
		bottom: unset;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

.ac-caseStudies-apps-3-star {
	height: $ac-global-space-3xl + 10px; 
	width: $ac-global-space-3xl + 10px; 
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: 5.5%;
	bottom: 13%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-4xl; 
		width: $ac-global-space-4xl; 
		left: 2%;
		bottom: 3%;
		z-index: 2;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}



// moreInfo section
.ac-caseStudies-moreInfo {
	background: $ac-color-gradient-light-reverse;
	display: flex;
	justify-content: center;
	// margin-bottom: -72px;
	min-height: $ac-caseStudies-model-section-height;
	padding: ($ac-caseStudies-wave-height + 40px) $ac-global-space-xl $ac-global-space-5xl;
	position: relative;
	width: 100%;
}

.ac-caseStudies-moreInfo-container {
	text-align: center;
	margin: 0 auto;
	max-width: 1030px;
}

.ac-caseStudies-moreInfo-content {
	width: 100%;
	max-width: $ac-global-content-container-width;
	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;
	margin: 0 auto;

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr !important;
		gap: 72px !important;
	}

	.absoluteBottom {
		position: absolute;
		bottom: 0%;
		left: 0%;
		right: 0%;
		margin: auto;

		@media screen and ($ac-global-breakpoints-md) {
			position: relative !important;
		}
	}
}

.ac-caseStudies-moreInfo-content .moreInfoIcon {
	margin: 0 auto $ac-global-space-2xl;
	font-size: $ac-global-space-4xl;
	color: $ac-color-neutral-3;
}

.ac-caseStudies-subheading.moreInfo {
	text-align: center;
}

.list-criteria ul li {
	list-style: disc !important;
}

.video-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
  }
  
  .video-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
  }