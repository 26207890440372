@import '../../styles/_index.scss';

// Local Variables
$ac-become-a-donor-wave-height: 136px; // See svg files for height
$ac-become-a-donor-hero-section-height: 400px;
$ac-become-a-donor-model-section-height: 312px;
$ac-become-a-donor-engineers-section-height: 344px;
$ac-become-a-donor-become-partner-form-width: 857px;
$ac-become-a-donor-become-partner-form-textarea-height: 224px;
$ac-become-a-donor-impact-section-height: 320px;

// Wave container
.ac-become-a-donor-wave {
	background-repeat: no-repeat;
	height: $ac-become-a-donor-wave-height;
	position: absolute;
	width: 100%;
	left: 0;
	z-index: 1;

	@media screen and ($ac-global-breakpoints-md) {
		bottom: -135px !important;
	}
}

// Content

.ac-become-a-donor {
    // background: $ac-color-red-2;
	// background: -webkit-linear-gradient(right, $ac-color-red-2, $ac-color-teal-6);
	// background: -moz-linear-gradient(right, $ac-color-red-2, $ac-color-teal-6);
	// background: linear-gradient(to left, $ac-color-red-2, $ac-color-teal-6);

}

.ac-become-a-donor-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-neutral-5;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-become-a-donor-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

.ac-become-a-donor-body {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
}

// Hero section
.ac-become-a-donor-hero {
	//background: url('../../assets/waves/wave-hero-offwhite.svg');
	//background-color: #fff;
	// background-position: 50% -200px;
	background-repeat: repeat-x;
	//color: $ac-color-neutral-5;
	min-height: $ac-become-a-donor-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
	z-index: 2;
}

.ac-become-a-donor-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-become-a-donor-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 125px;
	top: 100px;
	width: $ac-global-space-6xl;
	// z-index: 0;

	> g {
		fill: $ac-color-purple-2;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; // 80px
		width: $ac-global-space-5xl; // 80px
		right: 275px;
		top: 225px;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-3xl; // 64px
		width: $ac-global-space-3xl; // 64px
		right: 125px;
		top: 310px;
	}
	&:nth-of-type(4) {
		height: $ac-global-space-2xl; // 64px
		width: $ac-global-space-2xl; // 64px
		right: 375px;
		top: 400px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Our success section

.ac-become-a-donor-success {
    height: 100%;
   	padding: $ac-global-space-6xl $ac-global-space-4xl 0px;
	margin-top: -$ac-global-space-5xl;
	background-color: $ac-color-neutral-0;

	@media screen and ($ac-global-breakpoints-md) {
		padding-bottom: 30px !important;
	}
}

.ac-become-a-donor-success-container {
    text-align: center;
}

.ac-become-a-donor-success-container h2 {
    margin-bottom: $ac-global-space-2xl;
}


.ac-become-a-donor-subtext {
    font-family: $ac-global-font-family;
    font-size: $ac-global-font-size-sm;
    color: $ac-color-neutral-5;
	max-width: $ac-global-content-container-width;
	//max-width: 50vw;
	width: 100%;
	margin: 0px auto $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
	
}


.ac-become-a-donor-success-content {
	max-width: $ac-global-content-container-width;
    display: grid;
    grid-template-columns: (1fr 1fr 1fr);
    gap: 100px;
    justify-self: center;
    margin: 0px auto $ac-global-space-sm;

	@media screen and ($ac-global-breakpoints-md) {
		max-width: 400px !important;
		grid-template-columns: 1fr;
		gap: 72px;
	}
}

.ac-become-a-donor-stat {
    font-family: $ac-global-font-family-bold;
    font-size: $ac-global-font-size-2xl;
    color: $ac-color-purple-1;
    margin-bottom: $ac-global-space-xl;
    margin-top: $ac-global-space-xl;
}

.ac-become-a-donor-stat-textbox {
    font-family: $ac-global-font-family;
    font-size: $ac-global-font-size-xs;
	color: $ac-color-neutral-5;
	line-height: $ac-global-line-height-md;
}



//Know where you make an impact
.ac-become-a-donor-impact-bottom-wave {
	background-repeat: no-repeat;
	background-position: center;
	height: $ac-become-a-donor-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}

.ac-become-a-donor-know-impact{
    background-color: $ac-color-purple-1;
	min-height: 600px;
	padding-top: $ac-global-space-5xl;

	@media screen and ($ac-global-breakpoints-md) {
		min-height: 100vh;
	}
}

.ac-our-stories-know-impact-content-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
	padding-top: 0px;
	
	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr !important;
		padding-left: 24px;
		padding-right: 24px;
	}
}

.ac-our-stories-know-impact-image{
    width: 500px;
    justify-self: end;
	align-self: center;
	
	@media screen and ($ac-global-breakpoints-md) {
		justify-self: center !important;
		margin-bottom: $ac-global-space-4xl !important;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		width: 100% !important;
	}
}

.ac-become-a-donor-know-impact-copy {
    color: $ac-color-neutral-0;
    justify-self: center;
    align-self: center;
    line-height: $ac-global-line-height-md;
	padding-left: 1rem;
	
	@media screen and ($ac-global-breakpoints-md) {
		max-width: 500px !important;
		padding-left: 0px !important;
	}
}

//Donate section

.ac-become-a-donor-donate {
	background: $ac-color-gradient-light;
	padding: 50px 150px;
	@media screen and ($ac-global-breakpoints-md) {
		padding: 50px 50px;
	}
}

.ac-become-a-donor-donate-star {
	height: $ac-global-space-7xl;
	width: $ac-global-space-7xl; 
	position: absolute;
	right: 4%;
	top: 0px !important;
	opacity: $ac-global-opacity-less;
	

	> g {
		fill: $ac-color-purple-2;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-4xl;
		width: $ac-global-space-4xl;
		left: 7%;
		top: 560px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

.ac-become-a-donor-donate-subheading {
	text-align: center;
	margin-bottom: $ac-global-space-xl;
}

.ac-become-a-donor-donate-subtext {
	text-align: center;
	margin-bottom: $ac-global-space-3xl;
}

.ac-become-a-donor-donate-content {
	height: 100%;
	padding-left: 70px;

	@media screen and ($ac-global-breakpoints-xs) {
		padding-left: 0px;
	}
}




// Contributors to our mission section
.ac-home-become-a-donor {
	background: $ac-color-neutral-0;
	display: flex;
	min-height: 300px;
	padding: $ac-global-space-xl $ac-global-space-xl $ac-global-space-5xl;
	margin-bottom: -$ac-global-space-3xl;
	width: 100%;
	position: relative;

	.ac-donor-contributors-heading {
		margin-bottom: $ac-global-space-4xl;
		text-align: center;
	}
}

.ac-become-a-donor-contributors-top-wave {
	background-position: 20% center;
	background-repeat: no-repeat;
	height: $ac-become-a-donor-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
	top: -$ac-become-a-donor-wave-height;
	left: 0px;
}

.ac-home-become-a-donor-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-home-become-a-donor-content {
	justify-items: center;
	display: grid;
	gap: $ac-global-space-5xl;
	grid-template-columns: repeat(4, 1fr);

	@media screen and ($ac-global-breakpoints-lg) {
		grid-template-columns: repeat(2, 1fr) !important;
	}

	// @media screen and ($ac-global-breakpoints-md) {
	// 	// gap: $ac-global-space-5xl !important;
	// 	gap: $ac-global-space-3xl + $ac-global-space-sm !important;
	// 	grid-template-columns: 1fr !important;
	// }

	@media screen and ($ac-global-breakpoints-md) {
		gap: $ac-global-space-5xl !important;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		// gap: $ac-global-space-3xl + $ac-global-space-sm;
		grid-template-columns: 1fr !important;
	}
}



.ac-home-become-a-donor-image {
	// height: $ac-global-space-5xl;
	// width: auto;

	height: $ac-global-space-5xl;
	width: auto;
	max-width: 100%;

	// @media screen and ($ac-global-breakpoints-lg) {
	// 	height: $ac-global-space-6xl !important;
	// }

	// @media screen and ($ac-global-breakpoints-sm) {
	// 	height: auto !important;
	// 	max-height: $ac-global-space-5xl + 10px !important;
	// 	width: auto !important;
	// }

	// @media screen and ($ac-global-breakpoints-xs) {
	// 	width: 90% !important;
	// 	height: auto !important;
	// 	// max-height: $ac-global-space-4xl + 12px !important;
	// 	max-height: unset !important;
	// }
}

// More ways to make an impact section
.ac-become-a-donor-support-mission {
	background: url('../../assets/waves/wave-actions-purple.svg');
	background-position: 50% 0%;
	background-repeat: repeat-x;
	color: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	height: 100%;
	padding: $ac-become-a-donor-wave-height $ac-global-space-xl $ac-global-space-4xl;
	position: relative;
	width: 100%;

	@media screen and ($ac-global-breakpoints-sm) {
		background-size: auto 100% !important;
	}

	h2 {
		margin-bottom: $ac-global-space-3xl + $ac-global-space-sm; // 48px
		text-align: center;
	}

	// Fontawesome icon
	svg {
		margin: 0 auto $ac-global-space-2xl;
		font-size: $ac-global-space-4xl;
	}
}

.ac-become-a-donor-support-mission-container {
	margin: 0 auto;
	max-width: 1030px;
	width: 100%;
	position: relative;
}

.ac-become-a-donor-support-mission-subheading {
	text-align: center;
}

.ac-become-a-donor-support-mission-content {
	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		gap: 72px;
	}
}

.ac-become-a-donor-support-mission-icons {
	margin: 0 auto $ac-global-space-2xl;
	font-size: $ac-global-space-4xl;
}

.ac-become-a-donor-support-mission-body {
	margin: 0 auto $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}
