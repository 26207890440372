@import '../../styles/_index.scss';

// Local Variables
$ac-our-impact-wave-height: 136px; // See svg files for height
$ac-our-impact-hero-section-height: 400px;
$ac-our-impact-model-section-height: 312px;
$ac-our-impact-engineers-section-height: 344px;
$ac-our-impact-become-partner-form-width: 857px;
$ac-our-impact-become-partner-form-textarea-height: 224px;
$ac-our-impact-impact-section-height: 320px;

// Wave container
.ac-our-impact-wave {
	background-repeat: no-repeat;
	height: $ac-our-impact-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
	left: 0;
}

// Content

.ac-our-impact {
    background: $ac-color-red-2;
    background: -webkit-linear-gradient(right, $ac-color-red-2, $ac-color-teal-6);
    background: -moz-linear-gradient(right, $ac-color-red-2, $ac-color-teal-6);
    background: linear-gradient(to left, $ac-color-red-2, $ac-color-teal-6);
}

.ac-our-impact-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-neutral-5;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-our-impact-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

.ac-our-impact-body {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
}

// Hero section
.ac-our-impact-hero {
	background: url('../../assets/waves/wave-hero-white.svg');
	background-position: 50% -200px;
	background-repeat: repeat-x;
	color: $ac-color-neutral-5;
	min-height: $ac-our-impact-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;

	@media screen and ($ac-global-breakpoints-xs) {
		background-position: 50% 0px !important;
		background-size: auto 100% !important;
		background-repeat: no-repeat !important;
		padding-bottom: 70px !important;
	}
}

.ac-our-impact-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-our-impact-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 125px;
	top: 100px;
	width: $ac-global-space-6xl;
	// z-index: 0;

	> g {
		fill: $ac-color-purple-2;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; // 80px
		width: $ac-global-space-5xl; // 80px
		right: 275px;
		top: 225px;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-3xl; // 64px
		width: $ac-global-space-3xl; // 64px
		right: 125px;
		top: 310px;
	}
	&:nth-of-type(4) {
		height: $ac-global-space-2xl; // 64px
		width: $ac-global-space-2xl; // 64px
		right: 375px;
		top: 400px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Our success section

.ac-our-impact-success {
    height: 100%;
	padding: $ac-global-space-5xl 150px ($ac-our-impact-wave-height * 1.5);
	
	@media screen and ($ac-global-breakpoints-md) {
		padding: $ac-global-space-5xl 24px ($ac-our-impact-wave-height * 1.5) !important;
	}
}

.ac-our-impact-success-container {
	max-width: $ac-global-content-container-width;
	text-align: center;
	margin: auto;
}

.ac-our-impact-success-container h2 {
    margin-bottom: $ac-global-space-4xl;
}

.ac-our-impact-success-content {
    display: grid;
    grid-template-columns: (1fr 1fr 1fr);
    gap: 100px;
    justify-self: center;
    margin-bottom: $ac-global-space-sm;

	@media screen and ($ac-global-breakpoints-md) {
		max-width: 400px !important;
		margin: auto !important;
		grid-template-columns: 1fr !important;
		gap: 72px !important;
	}
}

.ac-our-impact-stat {
    font-family: $ac-global-font-family-bold;
    font-size: $ac-global-font-size-2xl;
    color: $ac-color-purple-1;
    margin-bottom: $ac-global-space-xl;
}

.ac-our-impact-stat-text {
	font-family: $ac-global-font-family;
	line-height: $ac-global-line-height-sm;
    font-size: $ac-global-font-size-md;
    color: $ac-color-neutral-5;
}

// Our Model section

.ac-our-impact-model {
	// padding-bottom: 100px;
	background-color: #fff;
}

.ac-our-impact-timeline {
	background: $ac-color-neutral-0;
	min-height: $ac-our-impact-engineers-section-height;
	padding: $ac-global-space-3xl $ac-global-space-xl;
	position: relative;
	max-width: $ac-global-content-width;
	// height: 800px;
	width: 100%;
	margin: auto;

	@media screen and (max-width: 375px) {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}
.MuiTimelineItem-missingOppositeContent:before {
	display: none;
}
.ac-our-impact-timeline-content {
	max-width: $ac-global-content-container-width;
	padding: $ac-global-space-xl;
	margin: auto;
	width: 100%;

	@media screen and ($ac-global-breakpoints-sm) {
		// max-width: 600px !important;
		padding: 0px !important;
	}
}

.ac-our-impact-timeline-item {
	display: flex;
	// align-items: center;
	// margin-bottom: 40px; /* ?? */
	min-height: 140px !important; /* ?? */
}

.ac-our-history-timeline-left {
	flex: 0 !important;
	margin-right: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: -110px; /* ?? */

	@media screen and ($ac-global-breakpoints-md) {
		display: block !important;
		flex: unset !important;
		margin-top: -4.5% !important;
		margin-right: 15px !important;
	}
}

.ac-our-impact-history-subheading {
	text-align: left;
	margin-top: $ac-global-space-xl;
	padding: 0px $ac-global-space-xl;
	max-width: $ac-global-content-container-width;
	margin: auto;
	width: 100%;
	margin-bottom: 80px;
}

.ac-our-impact-history-timeline-event {
	color: $ac-color-neutral-5;
	font-family: $ac-global-font-family;
	line-height: $ac-global-line-height-md;
}

.ac-our-impact-history-timeline-icon {
	width: 90px;
	// display: flex;
	// margin-right: $ac-global-space-2xl;
	// margin-top: -16px;
	// margin-bottom: $ac-global-space-2xl;

	@media screen and ($ac-global-breakpoints-xs) {
		    margin-left: 25px !important;
		}
}

.ac-our-impact-history-timeline-dot {
	background-color: $ac-color-teal-1 !important;
}

.ac-our-impact-timeline-info {
	display: grid;
	grid-template-columns: 20% 70%;
	column-gap: 40px;
	align-items: center;
	margin-top: -110px; /* ?? */

	@media screen and ($ac-global-breakpoints-md) {
		display: flex !important;
		flex-direction: column !important;
		margin-top: 0px !important; // ??
		padding-top: 0px !important;
		padding-bottom: $ac-global-space-4xl !important;
		align-items: flex-start !important;
	}

	@media screen and ($ac-global-breakpoints-xs) {
		padding-bottom: $ac-global-space-6xl !important;
	}

	.iconHeader {
		@media screen and ($ac-global-breakpoints-xs) {
		    margin-top: -48px !important;
		}
	}
}

.ac-our-impact-timeline-info.last {
	@media screen and ($ac-global-breakpoints-xs) {
		padding-bottom: $ac-global-space-3xl !important;
	}
}

.ac-our-impact-timeline-info h3 {
	height: fit-content;
	margin-left: 50px;

	@media screen and ($ac-global-breakpoints-md) {
		margin: 0px 0px 20px 0px !important;
	}
}

// Our story section
.ac-our-impact-disciplines {
	background: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
    height: 1000px;
	padding: $ac-global-space-3xl $ac-global-space-xl;
	position: relative;
	width: 100%;
}

.ac-our-impact-our-story-container {
	align-items: center;
    display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: unset;
	position: relative;
	width: 100%;
    padding: $ac-global-space-3xl 0;
}

.ac-our-impact-disciplines-content > .ac-card {
	box-shadow: none !important;
	display: block !important;
}

//Our Stories Section

.ac-our-impact-our-stories {
	padding: $ac-global-space-6xl 0 ($ac-our-impact-wave-height + 100px) 0;
	background-repeat: no-repeat;
	background-position: center top;
	background-color: $ac-color-neutral-0;
}

.ac-our-impact-our-stories-subheading {
	text-align: left;
	margin-top: $ac-global-space-xl;
	padding: $ac-global-space-xl;
	max-width: $ac-global-content-container-width;
	margin: auto;
	width: 100%;
}

.ac-our-impact-our-stories-carousel img {
 width: $ac-global-space-4xl;
 display: flex;
 position: fixed;
 left: 150px;
 top: 45px;
}


// Support our mission section
.ac-our-impact-support-mission {
	background: url('../../assets/waves/wave-actions-purple.svg');
	background-position: 50% 0%;
	background-repeat: repeat-x;
	color: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	height: 100%;
	padding: $ac-our-impact-wave-height $ac-global-space-xl $ac-global-space-4xl;
	position: relative;
	width: 100%;
	margin-top: -$ac-our-impact-wave-height;

	@media screen and ($ac-global-breakpoints-sm) {
		background-size: auto 100% !important;
	}

	h2 {
		margin-bottom: $ac-global-space-3xl + $ac-global-space-sm; // 48px
	}

	// Fontawesome icon
	svg {
		margin: 0 auto $ac-global-space-2xl;
		font-size: $ac-global-space-4xl;
	}
}

.ac-our-impact-support-mission-container {
	margin: 0 auto;
	max-width: 1030px;
	width: 100%;
	position: relative;
}

.ac-our-impact-support-mission-subheading {
	text-align: center;
}

.ac-our-impact-support-mission-content {
	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		gap: 72px;
	}
}

.ac-our-impact-support-mission-icons {
	margin: 0 auto $ac-global-space-2xl;
	font-size: $ac-global-space-4xl;
}

.ac-our-impact-support-mission-body {
	margin: 0 auto $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}
