@import '../../styles/_index.scss';

// Local Variables
$ac-home-wave-height: 136px; // See svg files for height
$ac-home-section-padding: 88px;
$ac-home-hero-section-height: 100vh;
$ac-home-hero-section-height-sm: 375px;
$ac-home-hero-section-image-width: 96.5px;
$ac-home-hero-section-image-height: 205.5px;
$ac-home-hero-section-heading-width: 604px;
$ac-home-mission-services-section-height: 736px;
$ac-home-people-section-height: 482px;
$ac-home-top-funders-section-height: 325px;
$ac-home-connect-section-height: 320px;

// Wave container
.ac-home-wave {
	background-position: 50% 0;
	background-repeat: repeat-x;
	height: $ac-home-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}

.fixedBackgroundVideo {
	position: absolute;
	top: 0;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	//z-index: -1;
  }
  /* Journey Section Media Queries Phone */
/* @media screen and (max-width:488px) { */
@media screen and (max-width: 767px) {
	.fixedBackgroundVideo {
	  display: none !important;
	}
  
	.fixedBackgroundImage {
	  display: block;
	  position: fixed;
	  top: 0;
	  min-width: 100%;
	  min-height: 100%;
	  width: auto;
	  height: auto;
	  z-index: -100;
	}
}

// Content
.ac-home-heading {
	@include ac-mx-heading-text;
	color: white;
	margin-top: $ac-global-space-5xl;
	max-width: $ac-home-hero-section-heading-width;

	span {
		color: $ac-color-brand-primary;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		margin-bottom: $ac-global-space-lg;
		font-size: $ac-global-space-xl;
		margin-top: 0;
	}
}

.ac-home-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

.ac-home-body {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
	span {
		color: $ac-color-brand-primary;
		font-weight: $ac-global-font-family-semibold;
	}
}

// Hero section
.ac-home-hero {
	background: $ac-color-gradient-light;
	display: flex;
	min-height: $ac-home-hero-section-height;
	justify-content: center;
	padding: $ac-home-section-padding $ac-global-space-xl;
	position: relative;
	width: 100%;

	@media screen and ($ac-global-breakpoints-sm) {
		min-height: $ac-home-hero-section-height-sm;
	}
}

.ac-home-hero-container {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;

	.ac-button {
		bottom: -50px;
		left: 0;
		position: absolute;
		z-index: $ac-zindex-fixed;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		flex-direction: column;

		.ac-button {
			bottom: -$ac-global-space-4xl;
		}
	}
}

.ac-home-hero-image {
	margin: 0 auto -100px;
	z-index: $ac-zindex-fixed;

	@media screen and ($ac-global-breakpoints-sm) {
		height: $ac-home-hero-section-image-height;
		margin: 0 auto -60px;
		width: $ac-home-hero-section-image-width;
	}
}

.ac-home-star-image {
	position: absolute;
	animation: spin linear infinite;

	&.-sm {
		animation-duration: 8s;
		height: $ac-global-space-xl;
		right: $ac-global-space-5xl;
		width: $ac-global-space-xl;
		top: 100px;

		@media screen and ($ac-global-breakpoints-sm) {
			height: $ac-global-space-md;
			right: 6%;
			width: $ac-global-space-md;
			top: 116px;
		}
	}

	&.-md {
		animation-name: spin-reverse;
		animation-duration: 10s;
		height: $ac-global-space-2xl;
		right: 130px;
		top: 100px;
		width: $ac-global-space-2xl;

		@media screen and ($ac-global-breakpoints-sm) {
			height: $ac-global-space-lg + $ac-global-space-2xs; // 18px
			right: 14%;
			width: $ac-global-space-lg + $ac-global-space-2xs; // 18px
			top: 118px;
		}
	}

	&.-lg {
		animation-duration: 12s;
		height: $ac-global-space-3xl + $ac-global-space-sm;
		right: 100px;
		top: 50px;
		width: $ac-global-space-3xl + $ac-global-space-sm;

		@media screen and ($ac-global-breakpoints-sm) {
			height: $ac-global-space-xl;
			right: 8%;
			width: $ac-global-space-xl;
			top: 140px;
		}
	}

	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes spin-reverse {
		100% {
			transform: rotate(-360deg);
		}
	}
}

// Our mission/services section
.ac-home-mission-and-services {
	background: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	min-height: $ac-home-mission-services-section-height;
	padding: $ac-global-space-3xl $ac-global-space-xl;
	position: relative;
	width: 100%;
}

.ac-home-mission-and-services-container {
	align-items: center;
	display: grid;
	gap: 96px $ac-global-space-5xl;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;

	h2 {
		text-align: left;
	}

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		gap: $ac-global-space-2xl;
	}
}

.ac-home-mission-content {
	@media screen and ($ac-global-breakpoints-md) {
		grid-row: 2;
		margin-bottom: $ac-global-space-3xl;
	}
}

// How we help people section
.ac-home-people {
	background: $ac-color-gradient-light;
	padding: ($ac-home-wave-height + 40px) 0; // 176px
	position: relative;
	display: flex;
	justify-content: center;
	min-height: $ac-home-people-section-height;
	width: 100%;

	@media screen and ($ac-global-breakpoints-sm) {
		padding: $ac-home-wave-height 0; // 176px
	}

	.ac-home-subheading {
		align-self: flex-start;
		display: flex;
		margin: 0 0 $ac-global-space-3xl 200px;
		max-width: $ac-global-content-container-width;

		@media screen and ($ac-global-breakpoints-lg) {
			margin-bottom: $ac-global-space-2xl;
			margin-left: 0px !important;
			padding: 0 $ac-global-space-xl;
		}
	}

	.ac-button.-secondary {
		margin-top: $ac-global-space-4xl;
	}
}

.ac-home-people-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: unset;
	position: relative;
	width: 100%;
}

// Our top funders section
.ac-home-top-funders {
	background: $ac-color-neutral-0;
	position: relative;
	display: flex;
	justify-content: center;
	min-height: $ac-home-top-funders-section-height;
	padding: $ac-global-space-3xl $ac-global-space-xl;
	width: 100%;

	.ac-home-subheading {
		margin-bottom: $ac-global-space-4xl;
		text-align: left;
	}
}

.ac-home-top-funders-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-home-top-funders-content {
	justify-items: center;
	display: grid;
	gap: $ac-global-space-5xl;
	grid-template-columns: repeat(4, 1fr);

	@media screen and ($ac-global-breakpoints-lg) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and ($ac-global-breakpoints-md) {
		gap: $ac-global-space-5xl;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		gap: $ac-global-space-3xl + $ac-global-space-sm; // 48px;
		grid-template-columns: 1fr;
	}
}

.ac-home-top-funders-image {
	height: $ac-global-space-5xl;
	width: auto;
	max-width: 100%;
}

// Let's connect section
.ac-home-connect {
	background: url('../../assets/waves/wave-actions-purple.svg');
	background-position: 50% 0;
	background-repeat: no-repeat;
	color: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	min-height: $ac-home-connect-section-height;
	padding: $ac-home-wave-height $ac-global-space-xl $ac-global-space-4xl;
	position: relative;
	width: 100%;

	h2 {
		margin-bottom: $ac-global-space-3xl + $ac-global-space-sm; // 48px
	}

	// Fontawesome icon
	svg {
		margin: 0 auto $ac-global-space-2xl;
		font-size: $ac-global-space-4xl;
	}

	@media screen and ($ac-global-breakpoints-xs){
		background-size: auto 100% !important;
	}
}

.ac-home-connect-container {
	margin: 0 auto;
	max-width: 1030px;
	width: 100%;
	position: relative;
}

.ac-home-connect-content {
	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		gap: 72px;
	}
}
