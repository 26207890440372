@import '../../styles/_index.scss';

.ac-checkbox {
	display: block;
	margin: 0 0 $ac-global-space-sm;
	position: relative;
}

// Hides default checkbox
.ac-checkbox-input {
	cursor: pointer;
	position: absolute;
	opacity: 0;
	width: 100%;
	z-index: 1;

	// Checkmark
	&:checked {
		+ label::before {
			background: $ac-color-brand-primary;
			border-color: $ac-color-brand-primary;
		}

		+ label::after {
			content: '';
			display: block;
			border: solid $ac-color-neutral-0;
			border-width: 0 2px 2px 0;
			position: absolute;
			transform: translate(-50%, -50%) rotate(45deg);
			width: 5px;
			height: 10px;
			top: 9px;
			left: $ac-global-space-sm;
		}
	}

	&:focus {
		+ label::before {
			box-shadow: $ac-global-button-focus-ring-thin;
			outline: none;
		}
	}

	&:focus:not(:focus-visible) {
		+ label::before {
			box-shadow: none;
		}
	}
}

.ac-checkbox-label {
	color: $ac-color-text-primary;
	cursor: pointer;
	display: block;
	font-family: $ac-global-font-family;
	font-size: $ac-global-font-size-2xs;
	letter-spacing: $ac-global-letter-spacing-wide;
	line-height: $ac-global-line-height-md;
	padding-left: $ac-global-space-xl;
	position: relative;

	&::before {
		background-color: transparent;
		border: 1px solid $ac-color-input-border;
		border-radius: $ac-global-border-radius-rounded;
		content: '';
		cursor: pointer;
		display: block;
		height: $ac-global-space-lg;
		left: 0;
		position: absolute;
		top: 10px;
		transform: translateY(-50%);
		width: $ac-global-space-lg;
	}
}
