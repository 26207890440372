@import '../../styles/_index.scss';

// Local Variables
$ac-footer-social-icons-size: 21px;

.ac-footer {
	background-color: $ac-color-brand-primary;
	color: $ac-color-neutral-0;
	margin: 0 auto;
	width: 100%;

	hr {
		background-color: rgba($ac-color-neutral-1, 0.5);
		border: none;
		height: 1px;
		margin: 0;
	}
}

.ac-footer-navigation {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	padding: $ac-global-space-3xl + $ac-global-space-lg $ac-global-space-xl; // 56px

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-gap: $ac-global-space-3xl;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		grid-template-columns: 1fr;
		grid-gap: $ac-global-space-3xl;

		&.-bottom {
			grid-row-gap: $ac-global-space-3xl + $ac-global-space-lg; // 56px
		}
	}
}

.ac-footer-item {
	letter-spacing: $ac-global-letter-spacing-wide;
	margin-bottom: $ac-global-space-md;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.ac-footer-item-link {
	color: $ac-color-text-secondary;
	text-decoration: none;

	&:hover {
		font-family: $ac-global-font-family-semibold;
	}

	&:active {
		color: currentColor;
	}

	&:focus {
		box-shadow: $ac-global-button-focus-ring-thick-inverted;
		outline: none;
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
	}
}

.ac-footer-social {
	grid-column: 4;

	ul {
		display: flex;
	}

	a {
		color: $ac-color-neutral-0;
		font-size: $ac-footer-social-icons-size;
		margin-right: $ac-global-space-xl;
		text-decoration: none;
		transition: background $ac-transition-time-normal $ac-transition-link,
			border $ac-transition-time-normal $ac-transition-link,
			box-shadow $ac-transition-time-normal $ac-transition-link,
			color $ac-transition-time-normal $ac-transition-link;

		&:active,
		&:visited {
			color: currentColor;
		}
	}

	@media screen and ($ac-global-breakpoints-sm) {
		grid-column: auto;
	}
}

.ac-footer-column {
	h3 {
		font-family: $ac-global-font-family-bold;
		font-size: $ac-global-font-size-sm;
		letter-spacing: $ac-global-letter-spacing-wide;
		margin-bottom: $ac-global-space-lg;
		text-transform: uppercase;
	}
}

@import '../../styles/_index.scss';

// Local variables
$ac-input-field-height: 44px;
$ac-input-field-min-width: 300px;

.ac-input-label {
	@include ac-mx-label-text;
	margin-bottom: $ac-global-space-md;
}

.ac-input-newsletter input {
	border: 1px solid $ac-color-neutral-2;
	border-radius: $ac-global-border-radius-rounded;
	color: $ac-color-neutral-4;
	font-size: $ac-global-font-size-sm;
	height: $ac-input-field-height;
	letter-spacing: $ac-global-letter-spacing-wide;
	min-width: $ac-input-field-min-width;
	padding: $ac-global-space-sm $ac-global-space-md;
	transition: background $ac-transition-time-normal $ac-transition-link,
		border $ac-transition-time-normal $ac-transition-link,
		box-shadow $ac-transition-time-normal $ac-transition-link,
		color $ac-transition-time-normal $ac-transition-link;

	&:hover,
	&:focus {
		border: 1px solid $ac-color-state-focus;
		box-shadow: $ac-global-focus-ring;
		outline: none;
	}

	.is-invalid & {
		border: 2px solid $ac-color-state-error;

		&:hover,
		&:focus {
			box-shadow: none;
		}
	}

	&::placeholder {
		color: $ac-color-text-placeholder;
		font-family: $ac-global-font-family;
		line-height: $ac-global-line-height-md;
	}
}

.ac-input-field-message {
	font-size: $ac-global-font-size-sm;
	letter-spacing: $ac-global-letter-spacing-wide;
	margin: $ac-global-space-md 0 0;

	.is-invalid & {
		color: $ac-color-state-error;
	}
}

.ac-newsletter button {
	@include ac-mx-label-text;
	align-items: center;
	border-radius: $ac-global-border-radius-rounded-lg;
	border-style: solid;
	border-width: $ac-global-border-width-thick;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	color: white;
	background-color: #630D45 !important;
	height: 40px;
	padding: 0 $ac-global-space-2xl;
	position: relative;
	text-decoration: none;
	transition: background $ac-transition-time-normal $ac-transition-link,
		border $ac-transition-time-normal $ac-transition-link,
		box-shadow $ac-transition-time-normal $ac-transition-link,
		color $ac-transition-time-normal $ac-transition-link;
	white-space: nowrap;
}
.ac-newsletter .msg-alert p{
	color: white !important;
}