@import '../../styles/_index.scss';

.ac-navigation {
	background-color: $ac-color-neutral-0;
	position: sticky;
	top: 0;
	z-index: $ac-zindex-fixed + 10;

	&.is-scroll {
		box-shadow: $ac-global-box-shadow;
	}
}

.ac-navigation-container {
	align-items: center;
	display: grid;
	grid-template-columns: min-content 1fr min-content;
	grid-template-rows: min-content;
	margin: 0 auto;
	max-width: $ac-global-content-width;
	padding: 0 $ac-global-space-2xl;
}

.ac-navigation-logo,
.ac-navigation-links,
.ac-navigation-ctas {
	align-items: center;
	display: flex;
}

.ac-navigation-logo {
	background-image: url('../../assets/graphics/ac-logo-color.png');
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: contain;
	height: 48px;
	margin-inline-end: $ac-global-space-3xl + $ac-global-space-sm; // 48px
	width: 100px;

	@media screen and ($ac-global-breakpoints-md) {
		margin-inline-end: $ac-global-space-xl;
		width: 80px;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		margin-inline-end: $ac-global-space-md;
		width: 60px;
	}
}

.ac-navigation-ctas {
	a {
		margin-inline-start: $ac-global-space-lg;

		&:first-of-type {
			color: white;
			border: none;
		}

		@media screen and ($ac-global-breakpoints-md) {
			margin-inline-start: $ac-global-space-md;
		}

		@media screen and ($ac-global-breakpoints-sm) {
			margin-inline-start: $ac-global-space-sm;
		}
	}
}

.ac-navigation-link-container {
	position: relative;

	> a {
		z-index: 1;

		&:focus {
			z-index: 2;
		}
	}

	&:hover {
		z-index: 2;

		> a {
			background-color: rgba($ac-color-purple-3, 0.2);
			color: $ac-color-purple-1;
		}

		.ac-navigation-link-menu {
			display: block;
		}
	}
}

.ac-navigation-link-menu {
	background-color: $ac-color-neutral-0;
	border-bottom-left-radius: $ac-global-border-radius-rounded;
	border-bottom-right-radius: $ac-global-border-radius-rounded;
	box-shadow: $ac-global-box-shadow;
	display: none;
	list-style: none;
	margin: 0;
	min-width: 220px;
	padding: 0;
	position: absolute;
	top: 100%;
}

.ac-navigation-link {
	color: $ac-color-text-primary;
	display: block;
	line-height: 1;
	padding: $ac-global-space-xl;
	text-decoration: none;
	transition: background $ac-transition-time-normal $ac-transition-link,
		border $ac-transition-time-normal $ac-transition-link,
		box-shadow $ac-transition-time-normal $ac-transition-link,
		color $ac-transition-time-normal $ac-transition-link;
	white-space: nowrap;

	&:hover {
		background-color: rgba($ac-color-purple-3, 0.2);
		color: $ac-color-purple-1;
	}

	&:focus {
		box-shadow: $ac-global-button-focus-ring-thin;
		outline: none;
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
	}
}
.mobile-menu {
	display: none;
}

@media (max-width: 1130px) {
	.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
		padding-bottom: 65px !important;
	}

	.mobile-menu {
		display: flex;
	}

	.mobile-menu hr {
		display: none !important;
	}

	.mobile-menu .MuiPaper-elevation1 {
		box-shadow: none !important;
	}

	.mobile-menu svg {
		color: $ac-color-purple-1 !important;
	}

	.mobile-menu .MuiAccordion-root:before {
		background-color: $ac-color-purple-3 !important;
		// display: none;
	}

	.MuiAccordion-root.Mui-expanded:before {
		opacity: 1 !important;
	}

	.mobile-menu .MuiAccordion-root:after {
	}

	.mobile-menu .MuiAccordionDetails-root {
		border-top-width: 1px !important;
    	border-top-color: $ac-color-purple-3 !important;
    	border-top-style: solid !important;
	}

	.app-bar {
		position: fixed;
		transition-property: margin, width;
		transition-timing-function: ease;
		color: black !important;
		background-color:rgba($ac-color-purple-3, 0.8) !important;
		box-shadow: none;
	}

	.MuiPaper-elevation4 {
		box-shadow: none !important;
	}

	.app-bar-shift {
		width: calc(100% - 250px);
		margin-left: 400px;
		transition-property: margin,width;
		transition-timing-function: ease-out;
	}

	.drawer {
		width: 400px;
		flex-shrink: 0;
	}
	.drawer-paper {
		width: 400px;
	}

	.drawer-header {
		display: flex;
		align-items: center;
	}

	.menu-logo {
		width: 100px;
		margin: 15px 0px 30px 20px;
	}

	.button-nested {
		padding-left: $ac-global-space-md;
	}

	.mobile-nav-buttons {
		display: flex;
		flex-direction: column;
		padding: 0px 16px;
	}

	.mobile-nav-buttons a {
		margin-bottom: 20px;

		&:first-of-type {
			color: $ac-color-brand-primary;
		}
	}

	.mobile-menu .ac-navigation-link-container a {
		color: $ac-color-neutral-4 !important;
		text-decoration: none !important;
	}


	.ac-navigation-container {
		display: none;
	}
}