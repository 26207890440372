@import '../../styles/_index.scss';

// Local variables
$ac-card-max-width-lg: 880px;
$ac-card-max-width-md: 630px;
$ac-card-max-width-sm: 279px;
$ac-card-padding-lg: 60px;
$ac-card-padding-md: 30px;

.ac-our-team-carousel-card {
	max-width: 1000px; // $ac-card-max-width-lg
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: transparent;
	// box-shadow: $ac-global-box-shadow;
	padding: $ac-card-padding-lg $ac-card-padding-lg 0px;

	@media screen and ($ac-global-breakpoints-md) {
		padding: $ac-card-padding-md;
		max-width: 95vw !important;
		flex-wrap: wrap;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		// max-width: $ac-card-max-width-sm;
		flex-wrap: wrap;
		height: auto;
		padding: $ac-global-space-lg;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
}

.ac-our-team-carousel-card-heading {
	font-size: 1.7em;
	display: flex;
	justify-content: center;
	padding-bottom: 40px;
}

.ac-our-team-carousel-card-image {
	// flex: 0 0 20%;
	flex: 1 0 100%;
	// margin: 0 $ac-global-space-sm;
	margin-bottom: 75px;

	img {
		width: 100%;
	}


	@media screen and ($ac-global-breakpoints-md) {
		margin-bottom: 10% !important;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		flex: 0 0 $ac-global-space-3xl;
	}

	@media screen and (max-width: 539px) {
    	margin-bottom: 12% !important;
	}
}

.ac-our-team-carousel-card-content {
	// flex: 1 1 80%;
	// margin-left: $ac-global-space-3xl + $ac-global-space-sm; // 48px
	text-align: center;
	padding: 0px 7%;

	@media screen and ($ac-global-breakpoints-md) {
		// margin-left: $ac-global-space-3xl;
	}

	@media screen and ($ac-global-breakpoints-md) {
		// margin: 0;
		
	}

	@media screen and ($ac-global-breakpoints-sm) {
		// margin-left: 0;
		// flex: 1 1 auto;
	}
}

.ac-our-team-carousel-card-description {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-xl;

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xs;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-2xs;
	}
}
