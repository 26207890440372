@import '../../styles/_index.scss';

// Local variables
$ac-textarea-input-field-min-height: 120px;
$ac-textarea-input-field-min-width: 300px;

.ac-textarea {
	display: flex;
	flex-direction: column;
}

.ac-textarea-label {
	@include ac-mx-label-text;
	margin-bottom: $ac-global-space-md;
}

.ac-textarea-field {
	border: 1px solid $ac-color-neutral-2;
	border-radius: $ac-global-border-radius-rounded;
	color: $ac-color-neutral-4;
	font-family: $ac-global-font-family;
	font-size: $ac-global-font-size-sm;
	height: 100%;
	letter-spacing: $ac-global-letter-spacing-wide;
	line-height: $ac-global-line-height-md;
	min-height: $ac-textarea-input-field-min-height;
	min-width: $ac-textarea-input-field-min-width;
	padding: $ac-global-space-sm $ac-global-space-md;
	transition: background $ac-transition-time-normal $ac-transition-link,
		border $ac-transition-time-normal $ac-transition-link,
		box-shadow $ac-transition-time-normal $ac-transition-link,
		color $ac-transition-time-normal $ac-transition-link;

	&:hover,
	&:focus {
		border: 1px solid $ac-color-teal-1;
		box-shadow: $ac-global-focus-ring;
		outline: none;
	}

	.is-invalid & {
		border: 2px solid $ac-color-state-error;

		&:hover,
		&:focus {
			box-shadow: none;
		}
	}

	&::placeholder {
		color: $ac-color-text-placeholder;
		font-family: $ac-global-font-family;
		line-height: $ac-global-line-height-md;
	}
}

.ac-textarea-field-message {
	font-size: $ac-global-font-size-sm;
	letter-spacing: $ac-global-letter-spacing-wide;
	margin: $ac-global-space-md 0 0;

	.is-invalid & {
		color: $ac-color-state-error;
	}
}
