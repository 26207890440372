/******************************** CURRICULUM ***************************/

.trainSection .sectionHeader {
    min-height: 50vh;
    margin-top: 0vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-color: #005157;
    color: white;
    padding: 45px 11.5vw;
  }
  
  .trainSection .sectionHeader h2 {
    font-family: "Poppins";
    color: white;
    margin-bottom: 16px;
  }
  
  .trainSection .sectionHeader p {
    padding-left: 116px;
  }
  
  .trainSection .particles {
    width: 30%;
  }
  
  /**** Curriculum Grid ****/
  
  .trainSection .imageColumn {
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    width: 33.33%;
    position:relative
  }
  
  .trainSection .imageCurricula {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    transition: 1s all ease;

  }
  
  .trainSection .imageColumn:hover .imageCurricula {
    transform: scale(1.5);
  }
  
  .trainSection .imageColumn:hover .imageCard {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-color: rgba(16, 154, 169, 0.8);
    padding: 30px;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 600;
  }
  
  .trainSection .imageCard {
    display: none;
  }
  
  .trainSection .curriculumModalOverlay {
    width: 50%;
    background-color: rgba(97, 139, 144, 0.8);
  }
  
  .trainSection .curriculumModalOverlay > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    width: 50%;
  }
  
  .trainSection .imageDescription {
    display: none;
  }
  
  @media screen and (max-width: 488px) {
    .trainSection .sectionHeader {
      min-height: 60vh !important;
      margin-top: 0vh !important;
    }
  
    .trainSection .sectionHeader p {
      padding-left: 0px !important;
    }
  
    .trainSection .particles {
      display: none;
    }
    .trainSection .imageColumn {
        padding: 0px;
        margin: 0px;
        overflow: hidden;
        width: 50%;
        position:relative
      }
  }
  
  @media screen and (max-width: 375px) {
  
    .trainSection .sectionHeader {
      margin-top: 15vh !important;
    }
    .trainSection .imageColumn {
        padding: 0px;
        margin: 0px;
        overflow: hidden;
        width: 100%;
        position:relative
      }
  }