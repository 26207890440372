@import '../../styles/_index.scss';

// Local variables
$ac-carousel-center-margin-lg: 100px;
$ac-carousel-center-margin-sm: 30px;

.ac-carousel {
	margin: 0 auto;
	position: relative;
	width: 100%;
}

.ac-carousel .slick-track {
	padding-bottom: 32px;
}

// React-Slick dots
.slick-dots {
	ul {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0;
	}

	li {
		align-items: center;
		display: inline-flex;
		margin: 0 $ac-global-space-xs;

		button {
			background: rgba($ac-color-teal-1, $ac-global-opacity-mid);
			border-radius: $ac-global-border-radius-circle;
			height: $ac-global-space-sm;
			width: $ac-global-space-sm;
			transition: background $ac-transition-time-normal $ac-transition-link,
				border $ac-transition-time-normal $ac-transition-link,
				box-shadow $ac-transition-time-normal $ac-transition-link,
				color $ac-transition-time-normal $ac-transition-link;

			&:focus {
				box-shadow: $ac-global-button-focus-ring-thick;
				outline: none;
			}

			&:focus:not(:focus-visible) {
				box-shadow: none;
			}

			&::before {
				content: none;
			}

			.-dark-mode & {
				background: rgba($ac-color-neutral-0, $ac-global-opacity-mid);
			}
		}
	}

	.slick-active {
		button {
			background: $ac-color-teal-1;
			height: $ac-global-space-lg;
			width: $ac-global-space-lg;
		}

		.-dark-mode & {
			button {
				background: $ac-color-neutral-0;
			}
		}
	}
}

// React-Slick slider
.slick-slide {
	> div {
		// padding: 0 0 $ac-global-space-2xl;
	}

	.ac-quote-card,
	.ac-card {
		transform: scale(0.9);

		// Note: show 3 cards on mobile
		@media screen and ($ac-global-breakpoints-sm) {
			transform: scale(0.95);
		}
	}
}

// React-Slick center card
.slick-slide.slick-center {
	> div {
		// margin: 0 $ac-carousel-center-margin-lg;
	}

	.ac-quote-card,
	.ac-card {
		transform: scale(1);
	}

	@media screen and ($ac-global-breakpoints-lg) {
		> div {
			margin: 0 $ac-global-space-3xl;
		}
	}

	@media screen and ($ac-global-breakpoints-md) {
		> div {
			margin: 0 $ac-carousel-center-margin-sm;
		}
	}

	@media screen and ($ac-global-breakpoints-sm) {
		> div {
			margin: auto;
		}

		// Note: show 3 cards on mobile
		.ac-quote-card,
		.ac-card {
			transform: scale(0.95);
		}
	}
}