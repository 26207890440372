// AnnieCannons

// Styles / Semantic Colors

@import 'color-palette';

// Brand
$ac-color-brand-primary: $ac-color-teal-1;
$ac-color-brand-secondary: $ac-color-purple-1;
$ac-color-brand-tertiary: $ac-color-neutral-4;
$ac-color-brand-quaternary: $ac-color-neutral-0;

// Accents
$ac-color-accent-primary-1: $ac-color-teal-2;
$ac-color-accent-primary-2: $ac-color-teal-3;
$ac-color-accent-primary-3: $ac-color-teal-4;
$ac-color-accent-primary-4: $ac-color-teal-5;

$ac-color-accent-secondary-1: $ac-color-purple-2;
$ac-color-accent-secondary-2: $ac-color-purple-3;
$ac-color-accent-secondary-3: $ac-color-purple-4;
$ac-color-accent-secondary-4: $ac-color-purple-5;

// Gradients
$ac-color-gradient-dark: linear-gradient(
	-45deg,
	$ac-color-brand-primary -30%,
	$ac-color-brand-secondary 100%
);
$ac-color-gradient-light: linear-gradient(
	-225deg,
	$ac-color-red-2 0%,
	$ac-color-teal-6 100%
);

$ac-color-gradient-light-reverse: linear-gradient(
	-225deg,
	 $ac-color-teal-6 0%,
	$ac-color-red-2 100%
);

// Text
$ac-color-text-primary: $ac-color-neutral-4;
$ac-color-text-secondary: $ac-color-neutral-0;
$ac-color-text-placeholder: $ac-color-neutral-2;
$ac-color-text-error: $ac-color-red-1;

// Button
$ac-color-button-primary-active: $ac-color-teal-0;

// Border
$ac-color-input-border: $ac-color-neutral-2;
$ac-color-border: $ac-color-neutral-1;

// States
$ac-color-state-focus: $ac-color-teal-1;
$ac-color-state-error: $ac-color-red-1;
