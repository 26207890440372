// AnnieCannons

// Styles / Typography

// google font helper:
// https://google-webfonts-helper.herokuapp.com/fonts/montserrat?subsets=latin

// Montserrat | https://fonts.google.com/specimen/Montserrat

// 400 / Regular
@font-face {
	font-family: 'Montserrat Regular';
	font-style: normal;
	font-weight: 400;
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('./type/montserrat-v15-latin-regular.woff2') format('woff2'),
		url('./type/montserrat-v15-latin-regular.woff') format('woff');
}

// 600 / Semibold
@font-face {
	font-family: 'Montserrat SemiBold';
	font-style: normal;
	font-weight: 600;
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('./type/montserrat-v15-latin-600.woff2') format('woff2'),
		url('./type/montserrat-v15-latin-600.woff') format('woff');
}

// 700 / Bold
@font-face {
	font-family: 'Montserrat Bold';
	font-style: normal;
	font-weight: 700;
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('./type/montserrat-v15-latin-700.woff2') format('woff2'),
		url('./type/montserrat-v15-latin-700.woff') format('woff');
}
