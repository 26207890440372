@import '../../styles/_index.scss';

.section {
	padding: $ac-global-space-3xl;

	&:nth-child(2n) {
		background: $ac-color-gradient-light;
	}

	&.-dark-mode {
		background: $ac-color-gradient-dark;
	}

	&.-carousel {
		h2 {
			padding: $ac-global-space-3xl $ac-global-space-3xl $ac-global-space-lg;
		}

		padding: $ac-global-space-3xl 0;
	}
}

.section-heading {
	margin-bottom: $ac-global-space-xl;

	.-dark-mode & {
		color: $ac-color-neutral-0;
	}
}

.section-subcontainer {
	display: flex;
	margin-bottom: $ac-global-space-3xl;

	> * {
		margin-right: $ac-global-space-xl;
	}
}

.demos-xl-heading {
	@include ac-mx-heading-text-xl;
	margin-bottom: $ac-global-space-xl;
}

.demos-heading {
	@include ac-mx-heading-text;
	margin-bottom: $ac-global-space-xl;
}

.demos-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
}

.demos-body {
	@include ac-mx-body-text;
}
