@import url("https://fonts.googleapis.com/css?family=Oswald:200");

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

@font-face {
  font-family: "Montserrat-UltraLight";
  src: url("../../assets/fonts/Montserrat-UltraLight.woff") format("woff"),
    url("../../assets/fonts/Montserrat-UltraLight.woff") format("truetype");
}

@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:500&display=swap');

.h1 {
  font-family: 'Montserrat Alternates';
  font-size: 28px;
	line-height: 42px;
	font-weight: 700;
	color: #FCFCFC;
	letter-spacing: 0.04em;
}

/*********************
Covid-19 Banner 
**********************/
.covid19Banner {
  width: 100vw;
  position: fixed;
  /* margin-top: 150px; */
  padding: 1em 10px;
  text-align: center;
  background-color: #109aa9;
  color: #fcfcfc;
  z-index: 98;
}

.covid19Banner h6 {
  margin-bottom: 0em;
}

.covid19Banner a {
  color: #630D45;
}

.covid19Banner a:hover {
  color: #E7B721;
  text-decoration: none;
}


@media screen and (max-width: 769px) {
  .covid19Banner {
    /* margin-top: 60px; */
    display: none;
  }
}


/************************ Home *************************/

/* Scroll Effects Image */
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

/*******************  Side Bar ***********************/

.sidebar {
  position: fixed;
  z-index: 10;
  right: 0;
  top: 13vh; /* without homepage COVID-19 Banner */
  /* top: 22vh; */ /* with COVID-19 Banner */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding: 20px;
  padding-bottom: 50px;
  cursor: pointer;
}

.sidebarListItem {
  display: flex;
  font-size: 0.8em;
  color: white;
  justify-content: center;
  align-items: center;
}

.sidebar p {
  color: white;
  margin-right: 10px;
}

.sidebar img {
  width: 60px;
}

/*************************** VIDEO BACKGROUND *******************/

.fixedBackgroundVideo {
  position: fixed;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
}

.fixedBackgroundImage {
  display: none;
}

/*************************** HOME SECTIONS *******************/

.homeWrapper section {
  width: 100%;
  height: 100vh;
}

.homeWrapper .journeySectionCentered {
  position: relative;
  max-height: 90vh;
  min-width: 100%;
  margin: 13vh 0 0 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.homeWrapper .sectionContentCentered {
  position: relative;
  width: 60%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeWrapper .journeySection {
  position: relative;
  max-height: 90vh;
  min-width: 100%;
  margin: 13vh 0 0 0;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.homeWrapper .joinSection {
  position: relative;
  max-height: 90vh;
  min-width: 100%;
  margin: 13vh 0 0 0;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.homeWrapper .sectionContent {
  position: relative;
  width: 85%;
  margin: 0px;
  max-width: none !important;
}

.homeWrapper .sectionRow {
  width: 100%;
}

.homeWrapper .sectionContent h4 {
  margin: 0px;
}

.homeWrapper .imageContainer {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeWrapper .imageContainer img {
  width: auto;
  height: 250px;
  /* -webkit-transition: -webkit-transform .8s ease-in-out;
  transition: transform .8s ease-in-out; */
}

.homeWrapper .imageContainer img:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

@media screen and (max-width: 767px) {
  .homeWrapper .imageContainer {
    display: none;
  }

  .homeWrapper .sectionRow {
    width: 100%;
  }

  .homeWrapper .mobileIconWrapper {
    padding-bottom: 20px;
  }
}

.homeWrapper .sectionGrid {
  display: flex;
  justify-content: space-around;
}

.homeWrapper .sectionGrid img {
  width: 30%;
}

.homeWrapper .acLogo {
  /* width: 40%; */
  width: 150px;
  margin-bottom: 10px;
}

.homeWrapper .acYTVid {
  width: 500px;
  height: 315px;
}

.homeWrapper .mobileIconWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeWrapper .mobileIcon {
  display: none;
}




@media screen and (max-width: 480px) {
  .homeWrapper .journeySectionCentered {
    max-height: 100% !important;
  }

  .homeWrapper .journeySection {
    max-height: 100% !important;
  }
  
  .homeWrapper .joinSection {
    max-height: 100% !important;
  }
}


@media screen and (min-width: 1024px) { /* With COVID19Banner */
  .homeWrapper .covid19Banner {
    margin-top: -3vh !important;
  }

  .homeWrapper .journeySectionCentered {
    margin-top: 16vh !important;
  }

  .homeWrapper .acLogo {
    width: 225px !important;
  }

}


@media screen and (min-width: 1200px) { /* With COVID19Banner */
  .sidebar {
    top: 22vh !important;
  }

  .homeWrapper .journeySection,
  .homeWrapper .joinSection {
    max-height: 100vh !important;
    padding: 25vh 50px 15px !important;
  }
}


@media screen and (min-width: 1440px) { /* With COVID19Banner */
  .sidebar {
    top: 21vh !important;
  }

  .homeWrapper .journeySection, .homeWrapper .joinSection {
    max-height: 90vh !important;
    padding: 20vh 50px 15px !important;
  }
}

/*************************** Text ****************************/

.titleText,
.nameSectionText {
  font-family: "Poppins";
  font-size: 2.5em;
  color: #e7b721;
}

.missionText {
  color: #109aa9;
  font-size: 1.5em;
  font-family: "Montserrat-UltraLight";
  padding: 10px 10px 10px 0px;
}

.paragraphText {
  color: white;
  font-family: "Poppins";
  font-weight: 400;
}

.subtitleText {
  color: rgb(110, 115, 129);
  font-size: 0.8em;
}

.missionStatement {
  color: #edf3f3;
  /* font-size: 1.5em; */
  font-family: "Poppins";
}

.journeyText {
  font-size: 2em;
  margin-top: 40px;
  font-family: "Poppins";
  color: #109aa9;
}

.homeWrapper .tabletIcon {
  display: none;
}

.joinTitleText {
  font-family: "Oswald";
  font-size: 2.5em;
  color: rgb(168, 186, 188);
}

.journeySectionCentered h3.nameSectionText {
  font-family: 'Montserrat Alternates';
  color: #d9dede;
}


/* Journey Section Media Queries tablet */
@media screen and (max-width: 768px) {
  .homeWrapper .tabletIcon {
    display: block;
    width: 25%;
    position: absolute;
    right: 20px;
    top: 20vh;
  }
}

/* Journey Section Media Queries tablet */
@media screen and (max-width: 1200px) {
  .sidebar {
    display: none;
  }
  .homeWrapper .tabletIcon {
    display: block;
    width: 25%;
    position: absolute;
    right: 20px;
    top: 5vh;
  }

  .homeWrapper .imageContainer {
    display: none;
  }
}

/* Journey Section Media Queries Phone */
/* @media screen and (max-width:488px) { */
@media screen and (max-width: 767px) {
  .fixedBackgroundVideo {
    display: none !important;
  }

  .fixedBackgroundImage {
    display: block;
    position: fixed;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
  }

  /* .homeWrapper .acLogo {
    width: 40%;
  } */

  .homeWrapper .tabletIcon {
    display: none;
  }

  .homeWrapper .sectionContentCentered {
    width: 90%;
  }

  .homeWrapper .sectionContent {
    width: 100%;
  }

  .homeWrapper .journeySection {
    padding: 10px;
    justify-content: center;
  }

  .homeWrapper .journeySection p,
  .homeWrapper .sectionContent h4 {
    margin-left: 20px;
  }

  .homeWrapper .joinSection {
    padding: 30px;
  }

  .homeWrapper .mobileIcon {
    display: block;
    width: 25%;
  }

  .titleText {
    font-size: 1.7em;
    margin-left: 20px;
  }

  .nameSectionText {
    font-size: 2em;
    margin-left: 0px;
  }

  .joinTitleText {
    font-size: 2em;
    margin-left: 0px;
  }

  .missionStatement {
    /* font-size: 1em; */
  }

  .paragraphText {
    font-size: 0.75em;
  }

  .missionText {
    font-size: 0.75em;
  }

  .homeWrapper .acYTVid {
    width: 350px;
    height: 315px;
  }

  .journeyText {
    font-size: 1.5em;
    margin-top: 20px;
  }
}

/* Modal Overlay Don't Delete! */
.videoPlayer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgb(168, 186, 188);
}

.videoPlayerIn {
  width: 100%;
  height: 100%;
}

.videoExit {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
}

/******************************** Buttons *****************************/
.homeButtonWrapper {
  width: 90%;
  display: flex;
  justify-content: space-around;
}

.hiddenBtnSpace {
  width: 285px !important;
}

@media screen and (max-width: 488px) {
  .homeButtonWrapper {
    width: 100%;
    height: 70vw !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}

.purpleBtns {
    border: none;
    font-family: 'Poppins';
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    background-color: #630D45 !important;
    padding: 20px 25px;
    max-width: 100%;
    min-width: 285px;
    cursor: pointer;
    border-radius: 0em;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
  }
  
  .purpleBtns:focus {
    box-shadow: none;
  }