@import '../../styles/_index.scss';

// Local Variables
@import '../../styles/_index.scss';

// Local Variables
$ac-our-process-wave-height: 136px; // See svg files for height
$ac-whatWeBuild-wave-height: 136px; // See svg files for height
$ac-whatWeBuild-hero-section-height: 400px;
$ac-whatWeBuild-model-section-height: 312px;
$ac-whatWeBuild-engineers-section-height: 344px;
$ac-whatWeBuild-become-partner-form-width: 857px;
$ac-whatWeBuild-become-partner-form-textarea-height: 224px;
$ac-whatWeBuild-impact-section-height: 320px;

// Wave container
.ac-whatWeBuild-wave {
	background-position: 50% 0;
	background-repeat: repeat-x;
	height: $ac-whatWeBuild-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}



// Content
.ac-whatWeBuild-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-brand-secondary;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-whatWeBuild-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

#engineers-heading {
	text-align: left;
}

.ac-whatWeBuild-text {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
}

// Hero section
.ac-whatWeBuild-hero {
	background: url('../../assets/waves/wave-hero-purple.svg');
	background-position: 50% -200px;
	background-repeat: repeat-x;
	color: $ac-color-text-secondary;
	min-height: $ac-whatWeBuild-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
}

.ac-whatWeBuild-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-whatWeBuild-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: -25px;
	top: 275px;
	width: $ac-global-space-6xl;
	// z-index: 0;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; 
		width: $ac-global-space-5xl; 
		right: 25px;
		top: 150px;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-4xl; 
		width: $ac-global-space-4xl; 
		right: 125px;
		top: 225px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Apps section
.ac-whatWeBuild-apps-section {
	background: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	min-height: $ac-whatWeBuild-engineers-section-height;
	padding: $ac-global-space-5xl $ac-global-space-xl $ac-global-space-2xl;
	position: relative;
	width: 100%;
}

.ac-whatWeBuild-apps-section-container {
	width: 100%;
	margin: 0 auto;
	// max-width: $ac-global-content-container-width;
	max-width: 840px;
	position: relative;


	.ac-whatWeBuild-text {
		margin-bottom: 15px;
	}
}

.ac-whatWeBuild-apps-section .appsDivs {
	position: relative;

	&:nth-of-type(3) {
		margin-bottom: 0px;
	}
	@media screen and ($ac-global-breakpoints-md) {
		// width: 85% !important;
		// margin: auto !important;
	}
}

.ac-whatWeBuild-apps-section .appIcons {
	width: auto;
	height: 200px;

	@media screen and ($ac-global-breakpoints-md) {
		margin-bottom: 40px !important;
	}
}

@media screen and ($ac-global-breakpoints-md) {
	.ac-whatWeBuild-apps-section .appIcons.webApp {
		margin-left: 30px !important;
	}

	.ac-whatWeBuild-apps-section .appIcons.websites {
		margin-left: 15px !important;
	}
}

.ac-whatWeBuild-apps-1-star {
	width: $ac-global-space-5xl;
	height: $ac-global-space-5xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: -17%;
	top: -1%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl + 10px; 
		width: $ac-global-space-3xl + 10px; 
		left: -23%;
		top: unset;
		bottom: 25%;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-3xl; 
		width: $ac-global-space-3xl; 
		top: unset;
		bottom: 0% !important;
		right: -17%;
		left: unset;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

.ac-whatWeBuild-apps-2-star {
	height: $ac-global-space-3xl + 10px; 
	width: $ac-global-space-3xl + 10px; 
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 0%;
	top: 8%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-4xl; 
		width: $ac-global-space-4xl; 
		right: -20%;
		top: unset;
		bottom: 20%;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

.ac-whatWeBuild-apps-3-star {
	height: $ac-global-space-3xl + 10px; 
	width: $ac-global-space-3xl + 10px; 
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: -14%;
	top: 28%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; 
		width: $ac-global-space-5xl; 
		left: -25.5%;
		top: unset;
		bottom: -5%;
		z-index: 2;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}



// Connect section
.ac-whatWeBuild-connect {
	background: $ac-color-gradient-light-reverse;
	display: flex;
	justify-content: center;
	// margin-bottom: -72px;
	min-height: $ac-whatWeBuild-model-section-height;
	padding: ($ac-whatWeBuild-wave-height + 40px) $ac-global-space-xl $ac-global-space-5xl;
	position: relative;
	width: 100%;
}

.ac-whatWeBuild-connect-container {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.ac-whatWeBuild-connect-content {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	width: 100%;
}

.ac-whatWeBuild-connect-content .connectIcon{
	margin: 0 auto $ac-global-space-2xl;
	font-size: $ac-global-space-4xl;
	color: $ac-color-neutral-3;
}

.ac-whatWeBuild-subheading.connect {
	text-align: center;
}
.ac-our-process-how {
	// height: 100%;
	background: url('../../assets/waves/wave-actions-purple.svg');
	background-position: 50% 0%;
	background-repeat: no-repeat;
	padding: ($ac-our-process-wave-height * 1.2) 150px;
	// background: $ac-color-gradient-dark;
	// margin-top: -$ac-our-process-wave-height;
	color: $ac-color-neutral-0;

	@media screen and ($ac-global-breakpoints-md) {
		padding: ($ac-our-process-wave-height * 1.2) $ac-global-space-xl;
	}
}

.ac-our-process-how-container {
	text-align: center;
	max-width: 900px;
    margin: auto;
}

.ac-our-process-how-container h2 {
	margin-bottom: $ac-global-space-2xl;
}


.ac-our-process-how-container p {
	line-height: $ac-global-line-height-md;
}

.ac-our-process-how-star {
	width: $ac-global-space-5xl + 10px;
	height: $ac-global-space-5xl + 10px;
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: 5%;
	top: -10%;
	bottom: 0%;
	margin: auto;


	> g {
		fill: $ac-color-purple-2;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl + 10px; 
		width: $ac-global-space-3xl + 10px; 
		left: 11%;
		top: 25%;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-4xl + 10px; 
		width: $ac-global-space-4xl + 10px; 
		left: 4%;
		top: 38%;
	}

	// Right side stars
	&:nth-of-type(4) {
		height: $ac-global-space-5xl; 
		width: $ac-global-space-5xl; 
		left: unset;
		right: 6%;
		top: 18%;
		bottom: 0%;
	}
	&:nth-of-type(5) {
		height: $ac-global-space-4xl; 
		width: $ac-global-space-4xl; 
		left: unset;
		right: 14%;
		top: 50%;
		bottom: 10%;
	}
	&:nth-of-type(6) {
		height: $ac-global-space-6xl; 
		width: $ac-global-space-6xl; 
		left: unset;
		right: 3%;
		top: unset;
		bottom: -2%;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}