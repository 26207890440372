@import '../../styles/_index.scss';

.ac-team-card{
    display: flex;
    flex-direction: column;
	padding: (25px 20px 25px 20px);
}
.ac-team-card-content {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
}
.ac-team-card-image-btn {
	background-size: 100%;
    display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: none;
	background-color: transparent;
	border-radius: 50%;
}

.ac-team-card-image-btn img,
.ac-team-card-modal-image {
	border-radius: 50%;
	width: 200px;
}

.ac-team-card-gradient {
	background: $ac-color-red-2;
	background: -webkit-linear-gradient(right, $ac-color-red-2, $ac-color-teal-6);
	background: -moz-linear-gradient(right, $ac-color-red-2, $ac-color-teal-6);
	background: linear-gradient(to left, $ac-color-red-2, $ac-color-teal-6);
	border-radius: 50%;
	opacity: .5;
	width: 200px;
	height: 200px;
	position: absolute;
	top: 0;
}

.ac-team-card-name {
	color: $ac-color-neutral-5;
	justify-items: center;
	font-family: $ac-global-font-family-semibold;
	padding-top: 15px;
}

.ac-team-card-title {
	color: $ac-color-neutral-5;
	justify-items: center;
	font-size: $ac-global-font-size-xs;
	padding: 10px 10px;
}

.ac-team-card-linkedin,
.ac-team-card-modal-linkedin {
    background-image: url('../../assets/icons/linkedin-icon-teal.png');
    background-size: cover;
	height: 40px;
	width: 40px;
    background-repeat: no-repeat;
}




/* AC team card modal */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
	z-index: 1050;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ac-team-card-modal {
	width: 75vw;
	max-width: 1440px;
	// max-height: 65vh;
	position: relative;
	background-color: #2E2E2E;
	box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
	color: #fff;
}

.ac-team-card-modal .topNav {
	width: 100%;
	height: 48px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	background-color: #109AA9;
}

.ac-team-card-modal .closeBtn {
	width: 26px;
	height: 26px;
	margin-right: 22px;
}
.ac-team-card-modal .closeBtn img {
	width: 100%;
}

.ac-team-card-modal-content {
	display: flex;
	flex-direction: row;
	padding: 7% 0px;
	font-family: $ac-global-font-family-semibold;
	font-size: $ac-global-font-size-md;
	line-height: $ac-global-line-height-md;
}

.ac-team-card-modal-name {
	color: $ac-color-teal-1;
}
.ac-team-card-modal-title {
	font-family: $ac-global-font-family;
	font-size: $ac-global-font-size-sm;
}

.ac-team-card-modal-content .rightColumn {
padding-right: 10%;
}

.ac-team-card-modal-content .rightColumn,
.ac-team-card-modal-content .rightColumn .bioText {
	height: 65vh;
	max-height: 65vh;
}

.ac-team-card-modal-content .rightColumn .bioText::-webkit-scrollbar {
  width: 12px;
}

.ac-team-card-modal-content .rightColumn .bioText::-webkit-scrollbar-thumb {
  width: 12px;
  height: 12px;
  background-image: url('https://anniecannons.com/wp-content/uploads/2019/10/tiny-teal-img.png');
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: center;
}
 
.ac-team-card-modal-content .rightColumn .bioText::-webkit-scrollbar-track {
  width: 12px;
  border-left: 4px solid #2E2E2E;
  border-right: 4px solid #2E2E2E;
  box-shadow: inset 12px 0px #BBBABA;
  outline: none;
}

.ac-team-card-modal-content .rightColumn .bioText {
    direction: rtl;
    overflow-y: auto;
    text-align: left;
	padding-left: 24px;
	display: flex;
    flex-direction: column;
}

.ac-team-card-modal-content .rightColumn .bioText span {
    direction: ltr;
	unicode-bidi: isolate;
	margin: auto;
}







@media screen and ($ac-global-breakpoints-xl) {  /* max-width: 1199.98 */
	.ac-team-card-modal {
		width: 95vw !important;
	}
}

@media screen and ($ac-global-breakpoints-lg) { /* max-width: 1199.98px */
	
}

@media screen and ($ac-global-breakpoints-md) { /* max-width: 991.98px */
	.ac-team-card-modal-content .rightColumn {
		padding-right: 24px !important;
	}

	.ac-team-card-modal-content .rightColumn,
	.ac-team-card-modal-content .rightColumn .bioText  {
		height: 46vh !important;
		max-height: 46vh !important;
	}
}


@media screen and ($ac-global-breakpoints-sm) { /* max-width: 767.98px */
	.ac-team-card-modal-content {
		flex-direction: column !important;;
	}

	.ac-team-card-modal-content .leftColumn {
		flex-direction: row !important;
		justify-content: flex-start !important;
		padding: 0px 30px !important;
	}

	.ac-team-card-modal-content .leftColumn .titleDiv {
		align-items: flex-start !important;
		margin-left: 30px !important;
	}

	.ac-team-card-modal-content .rightColumn {
		padding-left: 4.5% !important;
	}
}


@media screen and ($ac-global-breakpoints-xs) { /* max-width: 575.98px */
	.ac-team-card-modal {
		width: 100vw !important;
	}


	.ac-team-card-modal-content .leftColumn {
		flex-direction: column !important;
		margin-bottom: 40px !important;
	}

	.ac-team-card-modal-content .leftColumn .titleDiv {
		align-items: center !important;
		margin-left: 0px !important;
	}

	.ac-team-card-modal-content .rightColumn,
	.ac-team-card-modal-content .rightColumn .bioText  {
		height: 57vh !important;
		max-height: 57vh !important;
	}
}