/* -- container -- */
.rodal,
.rodal-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.rodal {
    position: absolute;
}

/* -- mask -- */
.rodal-mask {
    position: absolute;
    background: rgba(0, 0, 0, .3);
}

/* -- dialog -- */
.rodal-dialog {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 700px !important;
    height: 700px !important;
    overflow:hidden;
    z-index: 101;
    padding: 15px;
    background: rgb(255, 225,168);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    -webkit-clip-path: circle(64.0% at 35% 68%);
    clip-path: circle(64.0% at 35% 68%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rodal-dialog:focus {
    outline: none;
}

/* -- close button -- */
.rodal-close {
    position: absolute;
    cursor: pointer;
    bottom: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
}

.rodal-close:before,
.rodal-close:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #999;
    border-radius: 100%;
    -webkit-transition: background .2s;
    transition: background .2s;
}

.rodal-close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rodal-close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.rodal-close:hover:before,
.rodal-close:hover:after {
    background: #333;
}

/* -- fade -- */
@-webkit-keyframes rodal-fade-enter {
    from {
        opacity: 0;
    }
}

@keyframes rodal-fade-enter {
    from {
        opacity: 0;
    }
}

.rodal-fade-enter {
    -webkit-animation: rodal-fade-enter both ease-in;
    animation: rodal-fade-enter both ease-in;
}

@-webkit-keyframes rodal-fade-leave {
    to {
        opacity: 0
    }
}

@keyframes rodal-fade-leave {
    to {
        opacity: 0
    }
}

.rodal-fade-leave {
    -webkit-animation: rodal-fade-leave both ease-out;
    animation: rodal-fade-leave both ease-out;
}

