@import '../../styles/_index.scss';

// Local Variables
$ac-our-team-wave-height: 136px; // See svg files for height
$ac-our-team-hero-section-height: 400px;
$ac-our-team-model-section-height: 312px;
$ac-our-team-engineers-section-height: 344px;
$ac-our-team-become-partner-form-width: 857px;
$ac-our-team-become-partner-form-textarea-height: 224px;
$ac-our-team-impact-section-height: 320px;

// Wave container
.ac-our-team-wave {
	background-position: 50% 0;
	background-repeat: repeat-x;
	height: $ac-our-team-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;


	@media screen and (max-width: 425px) {
		bottom: -60px !important;
	}
}


// Content

.ac-our-team-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	// span {
	// 	color: $ac-color-brand-secondary;
	// }

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-our-team-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

.ac-our-team-body {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
}

// Hero section
.ac-our-team-hero {
	background: url('../../assets/waves/wave-hero-purple.svg');
	background-position: 50% -200px;
	background-repeat: repeat-x;
	color: $ac-color-text-secondary;
	min-height: $ac-our-team-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
}

.ac-our-team-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-our-team-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: -25px;
	top: 275px;
	width: $ac-global-space-6xl;
	// z-index: 0;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; // 80px
		width: $ac-global-space-5xl; // 80px
		right: 25px;
		top: 150px;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-4xl; // 64px
		width: $ac-global-space-4xl; // 64px
		right: 125px;
		top: 225px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Our team carousel section
.ac-our-team-carousel-star {
	height: $ac-global-space-5xl; // 80px
	width: $ac-global-space-5xl; // 80px
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: -1.5%;
	top: 40%;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-7xl;
		width: $ac-global-space-7xl; 
		left: -2.5%;
		top: 57%;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}


.ac-our-team-carousel-section {
	background: $ac-color-neutral-0;
	padding-bottom: 0px; // $ac-our-team-wave-height + 40px; // 176px
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 100px;

	@media screen and ($ac-global-breakpoints-sm) {
		padding: $ac-global-space-4xl 0 0 !important; // 176px
	}
}

.ac-our-team-section {
	background: $ac-color-neutral-0;
	padding-bottom: 0px; // $ac-our-team-wave-height + 40px; // 176px
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 100px;

	@media screen and ($ac-global-breakpoints-sm) {
		padding: $ac-global-space-4xl 0 0 !important; // 176px
	}
}

.ac-our-team-carousel-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: unset;
	position: relative;
	width: 100%;
}

.ac-our-team-carousel-container .ac-carousel .slick-track {
	@media screen and (max-width: 450px) {
		padding-bottom: 0 !important;
	}
}

.ac-our-team-carousel-container .slick-slide {
	opacity: 0;
	transition: opacity 0.5s;
}

.ac-our-team-carousel-container .slick-slide.slick-active.slick-center.slick-current {
	opacity: 1;
}

.ac-our-team-carousel-subheading {
	align-self: flex-start;
	display: flex;
	margin: 0 auto $ac-global-space-3xl;
	max-width: $ac-global-content-container-width;
	width: 100%;

	@media screen and ($ac-global-breakpoints-lg) {
		margin-bottom: $ac-global-space-2xl;
		padding: 0 $ac-global-space-xl;
	}
}


.ac-our-team .slick-prev,
.ac-our-team .slick-next {
	display: flex;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	border-radius: 50%;
	background-color: $ac-color-teal-1;
	top: 40%;

	@media screen and (max-width: 787px) {
		top: 38% !important;
	}

	@media screen and (max-width: 417px) {
		top: 33% !important;
	}

	@media screen and (max-width: 340px) {
		top: 30% !important;
	}
}
.ac-our-team .slick-prev,
.ac-our-team .slick-next,
.ac-our-team .slick-prev:before,
.ac-our-team .slick-next:before {
	width: 60px;
	height: 60px;
	z-index: 5;
	font-size: 30px;
	align-self: center;
}

.ac-our-team .slick-prev:before,
.ac-our-team .slick-next:before {
	font-family: 'system-ui';
	opacity: 1;
}

.ac-our-team .slick-prev {
	left: calc(50% - 500px + 30px); // from center(50%) - half of card max-width(1000px=500px) + card padding(60px=30px)

	@media screen and ($ac-global-breakpoints-md) {
		left: 3% !important;
	}
}
// .ac-our-team .slick-prev:before {

// }

.ac-our-team .slick-next {
	right: calc(50% - 500px + 30px);

	@media screen and ($ac-global-breakpoints-md) {
		right: 3% !important;
	}
}
// .ac-our-team .slick-next:before {
	
// }



.ac-our-team .slick-dots {
	bottom: 170px;

	@media screen and ($ac-global-breakpoints-md) {
		bottom: 26.5% !important;
	}

	@media screen and (min-width: 768px) and (max-width: 787px) {
		bottom: 31.5% !important;
	}

	@media screen and (max-width: 660px) {
		bottom: 33% !important;
	}

	@media screen and (max-width: 539px) {
		bottom: 38% !important;
	}

	@media screen and (max-width: 456px) {
		bottom: 43.5% !important;
	}

	@media screen and (max-width: 417px) {
		bottom: 47% !important;
	}

	@media screen and (max-width: 377px) {
		bottom: 52% !important;
	}
	
	@media screen and (max-width: 340px) {
		bottom: 55% !important;
	}
}








// Our team section

.ac-our-team-staff {
	width: 100%;
  display: flex;
	justify-content: center;
	background: $ac-color-red-2;
	background: -webkit-linear-gradient(left, $ac-color-red-2, $ac-color-teal-6);
	background: -moz-linear-gradient(left, $ac-color-red-2, $ac-color-teal-6);
	background: linear-gradient(to right, $ac-color-red-2, $ac-color-teal-6);
	// min-height: 1000px;
	padding: ($ac-our-team-wave-height + 10px) $ac-global-space-xl ($ac-our-team-wave-height + 40px);
	// padding-bottom: 50px;
	// max-width: $ac-global-content-width;

	@media screen and (max-width: 425px) {
		padding-top: 90px !important;
	}
}

.ac-our-team-staff-content {
  display: grid;
  grid-template-columns: (1fr 1fr 1fr 1fr);
	border-bottom: 1px;
	max-width: $ac-global-content-container-width;
	padding-bottom: 50px;
}

.ac-our-team-board-content {
	display: grid;
	grid-template-columns: (1fr 1fr 1fr 1fr);
	  border-bottom: 1px;
	  max-width: $ac-global-content-container-width;
	  padding-bottom: 150px;
}

.ac-our-team-teaching-subheading {
	display: flex;
	justify-content: center;
	padding: 40px 40px 20px 40px;
}

.ac-our-team-leadership-subheading {
	display: flex;
	justify-content: center;
	padding: 50px 50px 20px 50px;
}



.ac-our-team-staff-separator {
	border-style: solid;
	border-width: 1px 0px 0px 0px;
	border-color: $ac-color-neutral-1;
}


// Our team multiply
.ac-our-team-multiply {
	background: url('../../assets/waves/wave-actions-purple.svg');
	background-position: 50% 0%;
	background-repeat: repeat-x;
	color: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	height: 100%;
	padding: $ac-our-team-wave-height $ac-global-space-xl $ac-global-space-4xl;
	position: relative;
	width: 100%;
	margin-top: -$ac-our-team-wave-height;

	@media screen and ($ac-global-breakpoints-md) {
		background-size: auto 100% !important;
	}

	h2 {
		margin-bottom: $ac-global-space-3xl + $ac-global-space-sm; // 48px
	}

	// Fontawesome icon
	svg {
		margin: 0 auto $ac-global-space-2xl;
		font-size: $ac-global-space-4xl;
	}
}

.ac-our-team-multiply-container {
	margin: 0 auto;
	max-width: 1030px;
	width: 100%;
	position: relative;
}

.ac-our-team-multiply-subheading {
	text-align: center;
}

.ac-our-team-multiply-content {
	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		gap: 72px;
	}
}

.ac-our-team-multiply-icons {
	margin: 0 auto $ac-global-space-2xl;
	font-size: $ac-global-space-4xl;
}

.ac-our-team-multiply-body {
	margin: 0 auto $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}







@media screen and ($ac-global-breakpoints-lg) { /* max-width: 1199.98px */
	.ac-our-team-staff-content {
		  grid-template-columns: 1fr 1fr 1fr !important;
	}
}

@media screen and ($ac-global-breakpoints-md) { /* max-width: 991.98px */
	.ac-our-team-staff-content {
		  grid-template-columns: 1fr 1fr !important;
	}
}

@media screen and ($ac-global-breakpoints-xs) { /* max-width: 575.98px */
	.ac-our-team-carousel-section {
		display:none;
	}
	.ac-our-team-staff-content {
		  grid-template-columns: 1fr !important;
	}
}
