@import '../../styles/_index.scss';

.ac-team-card{
    display: flex;
    flex-direction: column;
	padding: (25px 20px 25px 20px);
}
.ac-team-card-content {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
}
.ac-press-card-image-btn {
	background-size: 100%;
    display: flex;
	height: 200px;
	justify-content: center;
	align-items: center;
	position: relative;
	border: none;
	background-color: transparent;
	border-radius: 50%;
}

.ac-team-card-image-btn img,
.ac-team-card-modal-image {
	border-radius: 50%;
	width: 200px;
}

.ac-team-card-gradient {
	background: $ac-color-red-2;
	background: -webkit-linear-gradient(right, $ac-color-red-2, $ac-color-teal-6);
	background: -moz-linear-gradient(right, $ac-color-red-2, $ac-color-teal-6);
	background: linear-gradient(to left, $ac-color-red-2, $ac-color-teal-6);
	border-radius: 50%;
	opacity: .5;
	width: 200px;
	height: 200px;
	position: absolute;
	top: 0;
}

.ac-team-card-name {
	color: $ac-color-neutral-5;
	justify-items: center;
	font-family: $ac-global-font-family-semibold;
	padding-top: 15px;
}

.ac-team-card-title {
	color: $ac-color-neutral-5;
	justify-items: center;
	font-size: $ac-global-font-size-xs;
	padding: 10px 10px;
}






@media screen and ($ac-global-breakpoints-xl) {  /* max-width: 1199.98 */
	.ac-team-card-modal {
		width: 95vw !important;
	}
}

@media screen and ($ac-global-breakpoints-lg) { /* max-width: 1199.98px */
	
}

@media screen and ($ac-global-breakpoints-md) { /* max-width: 991.98px */
	.ac-team-card-modal-content .rightColumn {
		padding-right: 24px !important;
	}

	.ac-team-card-modal-content .rightColumn,
	.ac-team-card-modal-content .rightColumn .bioText  {
		height: 46vh !important;
		max-height: 46vh !important;
	}
}


@media screen and ($ac-global-breakpoints-sm) { /* max-width: 767.98px */
	.ac-team-card-modal-content {
		flex-direction: column !important;;
	}

	.ac-team-card-modal-content .leftColumn {
		flex-direction: row !important;
		justify-content: flex-start !important;
		padding: 0px 30px !important;
	}

	.ac-team-card-modal-content .leftColumn .titleDiv {
		align-items: flex-start !important;
		margin-left: 30px !important;
	}

	.ac-team-card-modal-content .rightColumn {
		padding-left: 4.5% !important;
	}
}


@media screen and ($ac-global-breakpoints-xs) { /* max-width: 575.98px */
	.ac-team-card-modal {
		width: 100vw !important;
	}


	.ac-team-card-modal-content .leftColumn {
		flex-direction: column !important;
		margin-bottom: 40px !important;
	}

	.ac-team-card-modal-content .leftColumn .titleDiv {
		align-items: center !important;
		margin-left: 0px !important;
	}

	.ac-team-card-modal-content .rightColumn,
	.ac-team-card-modal-content .rightColumn .bioText  {
		height: 57vh !important;
		max-height: 57vh !important;
	}
}