@import '../../styles/_index.scss';

// Local variables
$ac-quote-card-max-width-lg: 880px;
$ac-quote-card-max-width-md: 630px;
$ac-quote-card-max-width-sm: 279px;
$ac-quote-card-padding-lg: 60px;
$ac-quote-card-padding-md: 30px;

.ac-quote-card {
	background: $ac-color-neutral-0;
	box-shadow: $ac-global-box-shadow;
	display: flex;
	justify-content: center;
	padding: $ac-quote-card-padding-lg;
	max-width: $ac-quote-card-max-width-lg;

	@media screen and ($ac-global-breakpoints-md) {
		padding: $ac-quote-card-padding-md;
		max-width: $ac-quote-card-max-width-md;
		flex-wrap: wrap;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		max-width: $ac-quote-card-max-width-sm;
		flex-wrap: wrap;
		height: auto;
		padding: $ac-global-space-lg;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
}

.ac-quote-card-content {
	flex: 1 1 80%;
	margin-left: $ac-global-space-3xl + $ac-global-space-sm; // 48px
	margin-top: $ac-global-space-lg;

	@media screen and ($ac-global-breakpoints-md) {
		margin-left: $ac-global-space-3xl;
	}

	@media screen and ($ac-global-breakpoints-md) {
		margin: 0;
		text-align: center;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		margin-left: 0;
		flex: 1 1 auto;
	}
}

.ac-quote-card-description {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-xl;

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xs;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-2xs;
	}
}

.ac-quote-card-author {
	font-family: $ac-global-font-family-bold;
	font-size: $ac-global-font-size-md;
}
