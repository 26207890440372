// AnnieCannons

// Styles / Index

@import 'reset';
@import 'typography';
@import 'functions';
@import 'mixins';
@import 'color-palette';
@import 'semantic-color-palette';
@import 'settings';

// Starter Code
html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	color: $ac-color-text-primary;
	font-family: $ac-global-font-family;
	height: auto;
	margin: 0;
	padding: 0;
	line-height: $ac-global-line-height-md;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $ac-global-font-family-bold;
}



.semiBold {
	font-family: $ac-global-font-family-semibold;
}


button {
	cursor: pointer;
}



.cursorPointer {
	cursor: pointer;
}




.overFlowHide {
	overflow: hidden !important;
}




.btnNoStyle {
	background-color: transparent;
	border: none;
	padding: 0;
}



.positionRelative {
	position: relative;
}



.tealLinks {
	color: $ac-color-teal-1;
	font-family: $ac-global-font-family-semibold;
	text-decoration: none;
}






/* Flex classes */
.flexRow,
.desktopFlexRow {
	display: flex;
	flex-direction: row;
}

.flexColumn {
	display: flex;
	flex-direction: column;
}

.flexColumnCentered {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.justifyContentStart {
	justify-content: flex-start;
}

.alignItemsCentered {
	align-items: center;
}

.alignFlexEnd {
	align-items: flex-end;
}


.flex1 {
	flex: 1;
}




.textAlignCenter {
	text-align: center;
}



.lineHeightMd {
	line-height: $ac-global-line-height-md;
}

.lineHeight3-5 {
	line-height: 3.5;
}





/* Width */
.desktopWidth45Percent {
	width: 45%;
}

.desktopWidth50Percent {
	width: 50%;
}





/* Margin */
.desktopMarginLeft40 {
	margin-left: 40px;
}

.desktopMarginRight40 {
	margin-right: 40px;
}

.desktopMarginRight60 {
		margin-right: 60px;
	}





.marginBottom10 {
	margin-bottom: 10px;
}

.marginBottom18 {
	margin-bottom: 18px;
}

.marginBottom25 {
	margin-bottom: 25px;
}

.marginBottom30 {
	margin-bottom: 30px;
}

.marginBottom40 {
	margin-bottom: 40px;
}

.marginBottom60 {
	margin-bottom: 60px;
}

.marginBottom100 {
	margin-bottom: 100px;
}








@media screen and (min-width: 991.99px) {
	.overMdHidden {
		display: none !important;
	}
}



@media screen and (min-width: 767.99px) {
	.overSmHidden {
		display: none !important;
	}
}



@media screen and ($ac-global-breakpoints-md) {
	.underMdHidden {
		display: none !important;
	}
	 /* Flex */
	.desktopFlexRow {
		flex-direction: column !important;
	}

	.desktopWidth45Percent {
		width: 100% !important;
	}

	.desktopWidth50Percent {
		width: 100% !important;
	}

	.desktopMarginLeft40 {
		margin-left: 0px !important;
	}

	.desktopMarginRight40 {
		margin-right: 0px !important;
	}

	.desktopMarginRight60 {
		margin-right: 0px !important;
	}

	.mobileTextAlignCenter {
		text-align: center !important;
	}

	.mobileColumnReversed {
		flex-direction: column-reverse !important;
	}
}





@media screen and ($ac-global-breakpoints-sm) {
	.underSmHidden {
		display: none !important;
	}
}




@media screen and (min-width: 575.99px) {
	.overXsHidden {
		display: none !important;
	}
}


@media screen and ($ac-global-breakpoints-xs) {
	.underXsHidden {
		display: none !important;
	}
}
