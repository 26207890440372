@import './_index.scss';

.ac-link {
	@include ac-mx-link-text;
	background: none;
	border: none;
	border-bottom: 0.5px solid $ac-color-neutral-4;
	color: $ac-color-brand-primary;
	cursor: pointer;
	padding: $ac-global-space-xs $ac-global-space-2xs;
	position: relative;
	text-decoration: none;
	transition: background $ac-transition-time-normal $ac-transition-link,
		border $ac-transition-time-normal $ac-transition-link,
		box-shadow $ac-transition-time-normal $ac-transition-link,
		color $ac-transition-time-normal $ac-transition-link;
	white-space: initial;

	// Disabled
	&:disabled,
	&.is-disabled {
		border-bottom: 0.5px solid $ac-color-neutral-2;
		color: $ac-color-teal-4;
		pointer-events: none;
	}

	// Focus
	&:focus {
		border-bottom: 0.5px solid transparent;
		box-shadow: $ac-global-button-focus-ring-thick;
		outline: none;
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
		border-bottom: none;
	}

	// Hover
	&:hover {
		background-color: $ac-color-brand-primary;
		border-bottom: 0.5px solid $ac-color-brand-primary;
		color: $ac-color-neutral-0;
	}

	// Note this is only for the arrow svg found on the Home page
	.ac-home-arrow-default {
		transform: rotate(90deg);

		g {
			stroke: $ac-color-neutral-0;
		}
	}
}
