@import '../../styles/_index.scss';

// Local Variables
$ac-volunteer-wave-height: 136px; // See svg files for height
$ac-volunteer-hero-section-height: 400px;
$ac-volunteer-model-section-height: 312px;
$ac-volunteer-engineers-section-height: 275px;
$ac-volunteer-become-partner-form-width: 857px;
$ac-volunteer-become-partner-form-textarea-height: 224px;
$ac-volunteer-impact-section-height: 320px;
$ac-home-top-funders-section-height: 325px;

// Wave container
.ac-volunteer-wave {
	background-repeat: repeat-x;
	height: $ac-volunteer-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}

// Content
.ac-volunteer-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-brand-secondary;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-volunteer-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

.ac-volunteer-body {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}

// Hero section
.ac-volunteer-hero {
	background: url('../../assets/waves/wave-hero-purple.svg');
	background-position: 50% bottom;
	background-repeat: no-repeat;
	color: $ac-color-text-secondary;
	min-height: $ac-volunteer-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
}

.ac-volunteer-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-volunteer-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 200px;
	top: 70px;
	width: $ac-global-space-6xl;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl * 2; // 80px
		width: $ac-global-space-3xl * 2; // 80px
		right: 425px;
		top: 290px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Our Volunteers section
.ac-volunteer-engineers {
	background: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	padding: $ac-global-space-5xl $ac-global-space-xl;
	position: relative;
	width: 100%;
}

.ac-volunteer-engineers-container {
	align-items: center;
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;

	.ac-volunteer-subheading {
		text-align: left;
	}

}

// Become a volunteer
.ac-volunteer-become-partner {
	background: $ac-color-gradient-light;
	padding: ($ac-global-space-5xl + $ac-global-space-sm) $ac-global-space-xl; // 88px
	margin-top: -$ac-global-space-5xl;
	position: relative;

	.ac-volunteer-subheading {
		text-align: left;
	}
}

.ac-volunteer-become-partner-form {
	background: $ac-color-neutral-0;
	border: 1px solid $ac-color-neutral-1;
	border-radius: $ac-global-border-radius-rounded-lg;
	margin: $ac-global-space-6xl auto;
	max-width: $ac-volunteer-become-partner-form-width;
	padding: $ac-global-space-4xl;

	@media screen and ($ac-global-breakpoints-md) {
		padding: $ac-global-space-2xl;
	}
}

.ac-volunteer-become-partner-form-column:nth-child(2) {
	display: flex;
	flex-direction: column;

	.ac-checkbox {
		flex: 1 1 auto;
		margin-top: $ac-global-space-xl;
	}

	.ac-textarea {
		flex: 1 1 $ac-volunteer-become-partner-form-textarea-height;
	}

	.ac-button {
		align-self: flex-end;
	}
}

.ac-volunteer-become-partner-form-container {
	display: grid;
	gap: $ac-global-space-4xl;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin-top: $ac-global-space-3xl;

	input {
		margin-bottom: $ac-global-space-lg;
	}

	@media screen and ($ac-global-breakpoints-md) {
		gap: $ac-global-space-lg;
		grid-template-columns: 1fr;

		> div:nth-child(2) {
			.ac-textarea {
				flex: 1 1 auto;
			}
		}
	}

	@media screen and ($ac-global-breakpoints-xs) {
		gap: 0px !important;
		grid-template-columns: repeat(1, 1fr) !important;

		> div:nth-child(2) {
			.ac-textarea {
				flex: unset  !important;
			}
		}

		> div:nth-child(2) {
			.ac-textarea .ac-textarea-field {
				width: 100% !important;
				min-width: 205px !important;
			}
		}
	}
}

@media screen and ($ac-global-breakpoints-xs) {
	.ac-volunteer-become-partner-form-column {
		width: 100% !important;
	}

	.ac-volunteer-become-partner-form-column .ac-input-field {
		width: 100% !important;
		min-width: 205px !important;
	}
}


.ac-volunteer-become-partner-form-column:nth-child(2) {
	display: flex;
	flex-direction: column;

	.ac-checkbox {
		flex: 1 1 auto;
		margin-top: $ac-global-space-xl;
	}

	.ac-textarea {
		flex: 1 1 $ac-volunteer-become-partner-form-textarea-height;
	}

	.ac-button {
		align-self: flex-end;
	}
}



// Our corporate partners section
.ac-become-a-volunteer-partners {
	background: $ac-color-neutral-0;
	position: relative;
	display: flex;
	justify-content: center;
	padding: $ac-global-space-3xl $ac-global-space-xl ($ac-global-space-3xl + 20);
	width: 100%;

	.ac-become-a-volunteer-subheading {
		margin-bottom: $ac-global-space-4xl;
		text-align: center;
	}
}

.ac-become-a-volunteer-partners-top-wave {
	background-position: 20% center;
	background-repeat: no-repeat;
	height: $ac-volunteer-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
	top: -$ac-volunteer-wave-height;
	left: 0px;
}

.ac-home-top-funders-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-volunteer-partners-content {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	// justify-items: center;
	// align-items: center;
	// display: grid;
	// gap: $ac-global-space-5xl;
	// grid-template-columns: repeat(4, 1fr);

	// @media screen and ($ac-global-breakpoints-md) {
	// 	gap: $ac-global-space-5xl;
	// }

	// @media screen and ($ac-global-breakpoints-sm) {
	// 	gap: $ac-global-space-3xl + $ac-global-space-sm; // 48px;
	// 	grid-template-columns: 1fr;
	// }
}

.ac-volunteer-partner-logos {
	width: auto;
	height: $ac-global-space-2xl;
}

// Make an impact section
.ac-volunteer-impact {
	background: url('../../assets/waves/wave-actions-purple.svg');
	background-position: 50% 0;
	background-repeat: repeat-x;
	color: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	min-height: $ac-volunteer-impact-section-height;
	padding: $ac-volunteer-wave-height $ac-global-space-xl
		$ac-global-space-4xl;
	position: relative;
	width: 100%;

	@media screen and ($ac-global-breakpoints-xs) {
		background-size: auto 100% !important;
	}

	h2 {
		margin-bottom: $ac-global-space-3xl + $ac-global-space-sm; // 48px
	}

	// Fontawesome icon
	svg {
		margin: 0 auto $ac-global-space-2xl;
		font-size: $ac-global-space-4xl;
	}
}

.ac-volunteer-impact-container {
	margin: 0 auto;
	max-width: 1030px;
	width: 100%;
	position: relative;
}

.ac-volunteer-impact-content {
	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		gap: 72px;
	}
}
