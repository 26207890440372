@import '../../styles/_index.scss';

// Local Variables
$ac-caseStudyTemplate-wave-height: 136px; // See svg files for height
$ac-caseStudyTemplate-hero-section-height: 400px;
$ac-caseStudyTemplate-model-section-height: 312px;
$ac-caseStudyTemplate-engineers-section-height: 344px;
$ac-caseStudyTemplate-become-partner-form-width: 857px;
$ac-caseStudyTemplate-become-partner-form-textarea-height: 224px;
$ac-caseStudyTemplate-impact-section-height: 320px;

// Wave container
.ac-caseStudyTemplate-wave {
	background-repeat: no-repeat;
	height: $ac-caseStudyTemplate-wave-height;
	position: absolute;
	width: 100%;
	left: 0;
	z-index: 1;

	@media screen and ($ac-global-breakpoints-md) {
		bottom: -135px !important;
	}
}


.ac-caseStudyTemplate-subheadings {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	// text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

.ac-caseStudyTemplate-subheadings.white {
	color: #fff;
}


.ac-caseStudyTemplate {
	color: $ac-color-neutral-5;

	.ac-caseStudyTemplate-problem-container ul,
	.ac-caseStudyTemplate-process ul {
		list-style: disc;
		margin-left: 20px;
	}

	.blueDiv {
		background-color: $ac-color-teal-5;
		opacity: $ac-global-opacity-most;
		border-radius: 5px;
	}

	.twoColumnGrid {
		display: grid;
		grid-template-columns: (1fr 1fr);
		text-align: center;
		gap: 70px;

		@media screen and ($ac-global-breakpoints-md) {
			grid-template-columns: (1fr);
			grid-column: 1;
			// gap: 50px;
		}
	}
}


// Hero section
.ac-caseStudyTemplate-hero {
	background: url('../../assets/waves/wave-hero-offwhite.svg');
	background-position: 50% bottom;
	background-repeat: no-repeat;
	color: $ac-color-neutral-5;
	// min-height: $ac-caseStudyTemplate-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
	z-index: 2;

	@media screen and ($ac-global-breakpoints-md) {
		background-size: auto 100% !important;	
	}

	.ac-caseStudyTemplate-hero-container {
		margin: 0 auto;
		max-width: $ac-global-content-container-width;
		position: relative;
		width: 100%;
	}

	.flexRow {
		padding: 40px 0px ($ac-caseStudyTemplate-wave-height - 20px);

		@media screen and ($ac-global-breakpoints-lg) {
			padding-top: 100px !important;
		}

		@media screen and ($ac-global-breakpoints-md) {
			flex-direction: column !important;
			align-items: flex-start !important;
		}
	}

	.ac-caseStudyTemplate-title {
		@include ac-mx-heading-text-xl;
		font-size: $ac-global-font-size-2xl;
		margin-bottom: $ac-global-space-xl;
		// padding-top: $ac-global-space-6xl;

		span {
			color: $ac-color-neutral-5;
		}

		@media screen and ($ac-global-breakpoints-md) {
			font-size: $ac-global-font-size-xl;
			margin-bottom: $ac-global-space-lg;
			margin-top: 0;
		}

		@media screen and ($ac-global-breakpoints-sm) {
			font-size: $ac-global-font-size-lg;
		}
	}



	.ac-caseStudyTemplate-subTitle {
		@include ac-mx-body-text;
	}

	.heroImg {
		width: auto;
		height: 260px;
		margin-left: 70px;

		@media screen and ($ac-global-breakpoints-md) {
			width: 50% !important;
			height: auto !important; //340px
			margin: $ac-global-space-4xl auto 0px !important;
		}
		@media screen and ($ac-global-breakpoints-sm) {
			width: 70% !important;
		}
		@media screen and ($ac-global-breakpoints-xs) {
			width: 100% !important;
		}
	}
}

.ac-caseStudyTemplate-hero-star {
	width: $ac-global-space-5xl;
	height: $ac-global-space-5xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	left: 2%;
	bottom: 35%;
	z-index: -1;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl; 
		width: $ac-global-space-3xl; 
		left: 9%;
		bottom: 25%;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-3xl; 
		width: $ac-global-space-3xl;
		right: 4%;
		top: 27%;
		left: unset; 
		bottom: unset;
	}
	&:nth-of-type(4) {
		height: $ac-global-space-5xl; 
		width: $ac-global-space-5xl; 
		right: 3.5%;
		top: 50%;
		left: unset;
		bottom: unset;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}



// Our overview section
.ac-caseStudyTemplate-overview {
    height: 100%;
   	padding: ($ac-global-space-6xl + 10px) $ac-global-space-4xl $ac-global-space-4xl;
	margin-top: -$ac-global-space-5xl;
	background-color: $ac-color-neutral-0;

	@media screen and ($ac-global-breakpoints-md) {
		padding-bottom: 30px !important;
	}
	@media screen and ($ac-global-breakpoints-md) {
		padding-left: 24px !important;
		padding-right: 24px !important;
	}
}

.ac-caseStudyTemplate-overview-container {
	max-width: 1045px;
	margin: auto;
}

.ac-caseStudyTemplate-overview-content {
	display: flex;
	flex-direction: row;
	padding-bottom: $ac-global-space-4xl;
	border-bottom: 1px solid rgba(148, 148, 148, 0.25);

	@media screen and ($ac-global-breakpoints-md) {
		flex-direction: column !important;
	}
	

	.blueDiv {
		width: 510px;
		height: fit-content;
		padding: 30px 45px;

		@media screen and ($ac-global-breakpoints-md) {
			margin: auto !important;
		}
		@media screen and ($ac-global-breakpoints-sm) {
			width: 100% !important;
		}
		@media screen and ($ac-global-breakpoints-xs) {
			padding-left: 24px !important;
			padding-right: 24px !important;
		}

		.clientTextLogo {
			width: 58%;
			margin-bottom: 10px;

			@media screen and ($ac-global-breakpoints-md) {
				width: 38% !important;
			}
			@media screen and ($ac-global-breakpoints-sm) {
				width: 28% !important;
			}
			@media screen and ($ac-global-breakpoints-xs) {
				width: 150px !important;
			}
		}

		.logoText {
			font-family: $ac-global-font-family-semibold;
			font-size: 24px;
			margin-bottom: 10px;
		}
	}

}

// .ac-caseStudyTemplate-overview-content h2 {
//     margin-bottom: $ac-global-space-2xl;
// }


.ac-caseStudyTemplate-subtext {
    font-family: $ac-global-font-family;
    font-size: $ac-global-font-size-sm;
	// max-width: $ac-global-content-container-width;
	// max-width: 50vw;
	width: 100%;
	margin: 0px auto $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}



/* Our Impact */
.ac-caseStudyTemplate-impact-content {
	padding-top: $ac-global-space-4xl;

	.desktop3ColumnRow {
		max-width: $ac-global-content-container-width;
		display: grid;
		grid-template-columns: (1fr 1fr 1fr);
		gap: 100px;
		justify-self: center;
		margin: 0px auto $ac-global-space-4xl;
		text-align: center;

		@media screen and ($ac-global-breakpoints-md) {
			max-width: 400px !important;
			grid-template-columns: 1fr;
			gap: 72px;
		}
	}

	.ac-caseStudyTemplate-stat {
		font-family: $ac-global-font-family-bold;
		font-size: $ac-global-font-size-2xl;
		color: $ac-color-purple-1;
		margin-bottom: $ac-global-space-xl;
		margin-top: $ac-global-space-xl;
	}

	.ac-caseStudyTemplate-stat-text {
		font-family: $ac-global-font-family;
		font-size: $ac-global-font-size-md;
		color: $ac-color-neutral-5;
		line-height: $ac-global-line-height-md;
	}

	.blueDiv {
		padding: 45px 30px;
	}
}

.ac-caseStudyTemplate-overview-star {
	width: $ac-global-space-5xl;
	height: $ac-global-space-5xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 0%;
	bottom: 55%;
	// z-index: -1;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl; 
		width: $ac-global-space-3xl; 
		right: 7%;
		bottom: 49%;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-3xl; 
		width: $ac-global-space-3xl;
		left: -0.5%;
		bottom: 21%;
		right: unset; 
	}
	&:nth-of-type(4) {
		height: $ac-global-space-3xl + 10px; 
		width: $ac-global-space-3xl + 10px; 
		left: 6%;
		bottom: 13.5%;
		right: unset;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}


/* Problem section */
.ac-caseStudyTemplate-problem {
    background-color: $ac-color-purple-1;
	height: 100%;

	.ac-caseStudyTemplate-problem-star {
	width: $ac-global-space-4xl - 5px;
	height: $ac-global-space-4xl - 5px;
	opacity: $ac-global-opacity-least;
	position: absolute;
	left: 3%;
	top: 35%;
	// z-index: -1;

	> g {
		fill: $ac-color-purple-2;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; 
		width: $ac-global-space-5xl; 
		left: 7%;
		top: 49%;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-6xl; 
		width: $ac-global-space-6xl;
		left: -3.5%;
		top: 51%;
	}
	&:nth-of-type(4) {
		height: $ac-global-space-4xl - 10px; 
		width: $ac-global-space-4xl - 10px; 
		right: 4%;
		top: 33%;
		left: unset;
	}

	&:nth-of-type(5) {
		height: $ac-global-space-5xl - 10px; 
		width: $ac-global-space-5xl - 10px;
		right: 0%;
		top: 49%;
		left: unset; 
	}
	&:nth-of-type(6) {
		height: $ac-global-space-6xl - 10px; 
		width: $ac-global-space-6xl - 10px; 
		right: 7%;
		top: 49%;
		left: unset;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}
}

.ac-our-stories-problem-content {
	max-width: 920px;
	margin: auto;
	color: #fff;
	padding: ($ac-caseStudyTemplate-wave-height + 50px) 24px $ac-global-space-4xl;
	
	@media screen and ($ac-global-breakpoints-xs) {
		padding-bottom: $ac-global-space-3xl;
	}
}

.ac-caseStudyTemplate-problem-bottom-wave {
	background-repeat: no-repeat;
	background-position: center;
	height: $ac-caseStudyTemplate-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}



// Our process section
.ac-caseStudyTemplate-process {
	position: relative;
	padding: ($ac-caseStudyTemplate-wave-height + $ac-global-space-4xl) 24px ($ac-global-space-5xl + 10px);

	@media screen and ($ac-global-breakpoints-xs) {
		padding-bottom: $ac-global-space-4xl;
	}

	.ac-caseStudyTemplate-process-container {
		max-width: 1045px;
		margin: auto;
	} 

	.ac-caseStudyTemplate-process-content {
		text-align: center;
		padding-bottom: $ac-global-space-4xl;
		border-bottom: 1px solid rgba(148, 148, 148, 0.25);
	}

	.processImages {
		width: 100%;
		height: auto;

		@media screen and ($ac-global-breakpoints-md) {
			width: 50% !important;
			margin: auto !important;
		}
		@media screen and ($ac-global-breakpoints-sm) {
			width: 70% !important;
		}
		@media screen and ($ac-global-breakpoints-xs) {
			width: 100% !important;
		}
	}

	.ac-caseStudyTemplate-solution-content {
		padding-top: $ac-global-space-4xl;
	}
}

.ac-caseStudyTemplate-process-subtext,
.ac-caseStudyTemplate-solution-content ul {
	margin-bottom: $ac-global-space-4xl;
}

.ac-caseStudyTemplate-process-star {
	height: $ac-global-space-7xl;
	width: $ac-global-space-7xl; 
	position: absolute;
	right: 4%;
	top: -100px;
	opacity: $ac-global-opacity-less;
	

	> g {
		fill: $ac-color-purple-2;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-4xl;
		width: $ac-global-space-4xl;
		left: 7%;
		top: 560px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}




// More info section
.ac-caseStudyTemplate-moreInfo {
	background: $ac-color-gradient-light-reverse;
	// color: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	height: 100%;
	padding: $ac-caseStudyTemplate-wave-height $ac-global-space-xl $ac-global-space-4xl;
	position: relative;
	width: 100%;

	h2 {
		margin-bottom: $ac-global-space-3xl + $ac-global-space-sm; // 48px
		text-align: center;
	}

	// Fontawesome icon
	svg {
		color: $ac-color-neutral-3;
		margin: 0 auto $ac-global-space-2xl;
		font-size: $ac-global-space-4xl;
	}
}

.ac-caseStudyTemplate-moreInfo-container {
	margin: 0 auto;
	max-width: 1030px;
	width: 100%;
	position: relative;
}

// .ac-caseStudyTemplate-moreInfo-subheading {
// 	text-align: center;
// }

.ac-caseStudyTemplate-moreInfo-content {
	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		gap: 72px;
	}
}

.ac-caseStudyTemplate-moreInfo-icons {
	margin: 0 auto $ac-global-space-2xl;
	font-size: $ac-global-space-4xl;
}

.ac-caseStudyTemplate-moreInfo-body {
	margin: 0 auto $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}
