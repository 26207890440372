// AnnieCannons

// Styles / Colors

// Neutrals
$ac-color-neutral-0: #ffffff;
$ac-color-neutral-1: #e7e7e7;
$ac-color-neutral-2: #949494;
$ac-color-neutral-3: #69687b;
$ac-color-neutral-4: #1e1e1e;
$ac-color-neutral-5: #000000;

// Teals
$ac-color-teal-0: #09646e;
$ac-color-teal-1: #0b818e;
$ac-color-teal-2: #48a1ab;
$ac-color-teal-3: #85c0c6;
$ac-color-teal-4: #c2dfe3;
$ac-color-teal-5: #e6f2f3;
$ac-color-teal-6: #f3fcfc;

// Purple
$ac-color-purple-1: #672558;
$ac-color-purple-2: #8d5c82;
$ac-color-purple-3: #b392ab;
$ac-color-purple-4: #d9c8d5;
$ac-color-purple-5: #efe9ee;

// Red
$ac-color-red-1: #be0000;
$ac-color-red-2: #f9f1ef;
