// AnnieCannons

// Styles / Global Settings

@import 'color-palette';

// Typography
$ac-global-font-size-2xs: 12px;
$ac-global-font-size-xs: 14px;
$ac-global-font-size-sm: 16px;
$ac-global-font-size-md: 20px;
$ac-global-font-size-lg: 28px;
$ac-global-font-size-xl: 36px;
$ac-global-font-size-2xl: 48px;

$ac-global-font-family: 'Montserrat Regular', sans-serif;
$ac-global-font-family-semibold: 'Montserrat SemiBold', sans-serif;
$ac-global-font-family-bold: 'Montserrat Bold', sans-serif;

$ac-global-letter-spacing-narrow: -0.28px;
$ac-global-letter-spacing-wide: 0.3px;
$ac-global-letter-spacing-wider: 0.7px;

$ac-global-line-height: 1.25;
$ac-global-line-height-xs: 1.1;
$ac-global-line-height-sm: 1.43;
$ac-global-line-height-md: 1.75;

// Sizing
$ac-global-space-2xs: 2px;
$ac-global-space-xs: 4px;
$ac-global-space-sm: 8px;
$ac-global-space-md: 12px;
$ac-global-space-lg: 16px;
$ac-global-space-xl: 24px;
$ac-global-space-2xl: 32px;
$ac-global-space-3xl: 40px;
$ac-global-space-4xl: 64px;
$ac-global-space-5xl: 80px;
$ac-global-space-6xl: 120px;
$ac-global-space-7xl: 160px;

// z-indez
// use low single digit z-index values of 1, 2, and 3 for default, hover, and active states.
// https://getbootstrap.com/docs/4.5/layout/overview/#z-index
$ac-zindex-dropdown: 1000;
$ac-zindex-sticky: 1020;
$ac-zindex-fixed: 1030;
$ac-zindex-modal-backdrop: 1040;
$ac-zindex-modal: 1050;
$ac-zindex-popover: 1060;
$ac-zindex-tooltip: 1070;

// Elements
$ac-global-border-radius-rounded: 4px;
$ac-global-border-radius-rounded-lg: 25px;
$ac-global-border-radius-circle: 50%;

$ac-global-border-width-thin: 1px;
$ac-global-border-width-thick: 2px;

$ac-global-opacity-least: 0.1;
$ac-global-opacity-less: 0.25;
$ac-global-opacity-mid: 0.5;
$ac-global-opacity-more: 0.65;
$ac-global-opacity-most: 0.8;

$ac-global-box-shadow: 0 4px 12px 0
	rgba($ac-color-neutral-2, $ac-global-opacity-mid);

$ac-global-focus-ring: 0 0 0 1px $ac-color-state-focus;
$ac-global-button-focus-ring-thick: 0 0 0 2px $ac-color-neutral-0,
	0 0 0 4px $ac-color-state-focus;
$ac-global-button-focus-ring-thick-inverted: 0 0 0 2px $ac-color-state-focus,
	0 0 0 4px $ac-color-neutral-0;
$ac-global-button-focus-ring-thin: 0 0 0 2px $ac-color-state-focus;
$ac-global-button-focus-ring-thin-inverted: inset 0 0 0 2px
	$ac-color-state-focus;

// Breakpoints Up
// https://getbootstrap.com/docs/4.5/layout/overview/#responsive-breakpoints
$ac-global-breakpoints-xs: 'max-width: 575.98px';
$ac-global-breakpoints-sm: 'max-width: 767.98px';
$ac-global-breakpoints-md: 'max-width: 991.98px';
$ac-global-breakpoints-lg: 'max-width: 1199.98px';
$ac-global-breakpoints-xl: 'max-width: 1200px';

// Content
$ac-global-content-width: 1440px;
$ac-global-content-container-width: 1140px;


// Animation
$ac-transition-link: cubic-bezier(0.075, 0.82, 0.165, 1);
$ac-transition-shift: cubic-bezier(0.77, 0, 0.175, 1);
$ac-transition-time-fast: 0.1s;
$ac-transition-time-normal: 0.3s;
$ac-transition-time-slow: 0.5s;
