@import '../../styles/_index.scss';

// Local Variables
$ac-our-mission-wave-height: 136px; // See svg files for height
$ac-our-mission-hero-section-height: 400px;
$ac-our-mission-model-section-height: 312px;
$ac-our-mission-engineers-section-height: 344px;
$ac-our-mission-become-partner-form-width: 857px;
$ac-our-mission-become-partner-form-textarea-height: 224px;
$ac-our-mission-impact-section-height: 320px;
$ac-our-impact-wave-height: 136px; // See svg files for height


// Wave container
.ac-our-mission-wave {
	background-position: 50% 0;
	background-repeat: repeat-x;
	height: $ac-our-mission-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}



// Content
.ac-our-mission-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-brand-secondary;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-our-mission-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

#engineers-heading {
	text-align: left;
}

.ac-our-mission-body {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
}

// Hero section
.ac-our-mission-hero {
	background: url('../../assets/waves/wave-hero-purple.svg');
	background-position: 50% -200px;
	background-repeat: repeat-x;
	color: $ac-color-text-secondary;
	min-height: $ac-our-mission-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
}

.ac-our-mission-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-our-mission-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: -25px;
	top: 275px;
	width: $ac-global-space-6xl;
	// z-index: 0;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-5xl; // 80px
		width: $ac-global-space-5xl; // 80px
		right: 25px;
		top: 150px;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-4xl; // 64px
		width: $ac-global-space-4xl; // 64px
		right: 125px;
		top: 225px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Our story section
.ac-our-mission-our-story {
	background: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	min-height: $ac-our-mission-engineers-section-height;
	padding: $ac-global-space-3xl $ac-global-space-xl;
	position: relative;
	width: 100%;
}

.ac-our-mission-our-story-container {
	align-items: center;
	display: grid;
	gap: 125px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;


	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		gap: $ac-global-space-2xl;
	}
}

// Our programs section
.ac-our-mission-programs {
	background: $ac-color-gradient-light;
	display: flex;
	justify-content: center;
	// margin-bottom: -72px;
	min-height: $ac-our-mission-model-section-height;
	padding: $ac-our-mission-wave-height $ac-global-space-xl;
	position: relative;
	width: 100%;
}

.ac-our-mission-subheading {
	text-align: center;

	@media screen and (max-width: 767.98px) {
		font-size: 28px;
		margin-bottom: $ac-global-space-xl;
	}

}

.ac-our-mission-programs-container {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.ac-our-mission-programs-content {
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	display: grid;
	gap: 200px;
	grid-template-columns: repeat(3, minmax(100px, 1fr));
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;

	.ac-our-mission-body {
		margin-top: $ac-global-space-xl;
	}
	
	img {
		width: $ac-global-space-6xl;
	}


	@media screen and ($ac-global-breakpoints-md) {
		max-width: 400px !important;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		gap: $ac-global-space-2xl;
	}

	h3 {
		justify-content: center;
		margin-top: $ac-global-space-xl;
	}
}

//Our Mission History Section
.ac-our-mission-timeline-container {
	background-color: $ac-color-neutral-0;
	position: relative;
	padding-bottom: $ac-our-mission-wave-height - 40px;
	background-position: center -20px;
	background-size: cover;
	background-repeat: no-repeat;
	background-blend-mode: multiply;
}

.ac-our-mission-timeline {
	// background: $ac-color-neutral-0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: $ac-our-mission-engineers-section-height;
	padding: $ac-global-space-3xl $ac-global-space-xl;
	position: relative;
	width: 100%;
	
	@media screen and ($ac-global-breakpoints-xs) {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}

.ac-our-mission-history-subheading {
	text-align: center;
}

.ac-our-mission-history-timeline-event {
	color: $ac-color-purple-1;
}

.ac-button.-secondary.-dark-mode.-dark-mode  {
	background-image: linear-gradient(
		-45deg,
		$ac-color-brand-primary -30%,
		$ac-color-brand-secondary 100%
	);
	width:fit-content;

	@media screen and ($ac-global-breakpoints-sm) {
		margin-bottom: 20px !important;
	}
}

.ac-our-mission-history-timeline-dot {
	background-color: $ac-color-teal-1 !important;
}

.ac-our-mission-history-timeline-button {
	margin-top: -15px;

	@media screen and ($ac-global-breakpoints-sm) {
		margin-top: -10px !important;
	}
}

.ac-our-mission-timeline-img img {
	width: 470px;

	@media screen and ($ac-global-breakpoints-md) {
		width: 100% !important;
	}
}

.lastTimelineSeparator .MuiTimelineConnector-root {
	display: none;
}


// Leadership Quote
.ac-our-mission-quote {
	background-color: $ac-color-neutral-4;
	background-repeat: repeat-x;
	padding: 20px 0 $ac-global-space-5xl; /* padding: $ac-our-mission-wave-height 0 $ac-global-space-5xl; */
	position: relative;
	width: 100%;
	z-index: 1000;

	.ac-our-mission-quote-container {
		display: grid;
		grid-template-columns: 60% 40%;
		margin: 0 auto;
		min-width: 100px;
		max-width: $ac-global-content-container-width;
		position: relative;


		@media screen and ($ac-global-breakpoints-lg) {
			padding-left: 24px !important;
			padding-right: 24px !important;
		}

		@media screen and ($ac-global-breakpoints-sm) {
			display: flex !important;
			flex-direction: column-reverse !important;
		}
	}
	.ac-our-mission-quote-attribution {
		justify-self: center;
		align-self: center;
		justify-content: center;
	}
	.ac-our-mission-quote-laura-photo {
		border-radius: 50%;
		width: 200px;
		// background-color: $ac-color-gradient-light;
	}

	.ac-our-mission-quote-text-style{
		color: $ac-color-neutral-0;
		font-family: $ac-global-font-family !important;
		font-size: 22px;
		line-height: $ac-global-line-height-md;
		margin-left: $ac-global-space-4xl;

		@media screen and ($ac-global-breakpoints-xs) {
			margin-left: 0px !important;
		}
	}

	.ac-our-mission-name-title {
		display: grid;
		color: $ac-color-neutral-0;
		justify-items: center;

		@media screen and ($ac-global-breakpoints-sm) {
			margin-bottom: 35px !important;
		}
	}

	.ac-our-mission-quote-star {
		height: 200px;
		opacity: $ac-global-opacity-less;
		position: absolute;
		right: -40px;
		bottom: 0px;
		width: 200px;

		> g {
			fill: $ac-color-neutral-2;
		}

		@media screen and ($ac-global-breakpoints-md) {
			display: none;
		}

	}
}

//Learn More section
.ac-our-mission-learn-more {
	display: flex;
	justify-content: center;
	padding: $ac-global-space-4xl;
	background-color: $ac-color-neutral-4;
	background-position: center top;
	background-repeat: no-repeat;

	@media screen and ($ac-global-breakpoints-xs) {
		background-size: auto 100% !important;
	}

	h2 {
		margin-bottom: $ac-global-space-3xl + $ac-global-space-sm;
		text-align: center;
	}
}

.ac-our-mission-learn-more-container {
	justify-content: center;
	width: 100%;
	margin: 0 auto;
	max-width: 1030px;
	position: relative;
	padding-top: 60px;
}

	.ac-our-mission-learn-more-content {
		display: grid;
		grid-template-columns: repeat(2, minmax(360px, 1fr));
		text-align: center;
		gap: 211px;
	
		@media screen and ($ac-global-breakpoints-md) {
			grid-template-columns: 1fr;
			gap: 72px;
		}
}

.ac-our-mission-learn-more-icons {
	margin: 0 auto $ac-global-space-2xl;
	font-size: $ac-global-space-4xl;
	color: $ac-color-neutral-3;
}

.ac-our-mission-learn-more-body {
	margin: 0 auto $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}

.ac-our-mission-learn-more-button {
	width: auto;
}

.ac-our-impact-our-stories {
	padding: $ac-global-space-6xl 0 ($ac-our-impact-wave-height + 100px) 0;
	background-repeat: no-repeat;
	background-position: center top;
	background-color: $ac-color-neutral-0;
}

.ac-our-impact-our-stories-subheading {
	text-align: left;
	margin-top: $ac-global-space-xl;
	padding: $ac-global-space-xl;
	max-width: $ac-global-content-container-width;
	margin: auto;
	width: 100%;
}

.ac-our-impact-our-stories-carousel img {
 width: $ac-global-space-4xl;
 display: flex;
 position: fixed;
 left: 150px;
 top: 45px;
}