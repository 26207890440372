// AnnieCannons

// Styles / Reset

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav {
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

p {
	margin: 0;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

img {
	width: 100%;
}
