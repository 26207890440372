@import '../../styles/_index.scss';

// Local Variables
$ac-refer-partner-wave-height: 136px; // See svg files for height
$ac-refer-partner-hero-section-height: 400px;
$ac-refer-partner-model-section-height: 312px;
$ac-refer-partner-engineers-section-height: 344px;
$ac-refer-partner-become-partner-form-width: 857px;
$ac-refer-partner-become-partner-form-textarea-height: 224px;
$ac-refer-partner-impact-section-height: 320px;

// Wave container
.ac-refer-partner-wave {
	background-repeat: repeat-x;
	height: $ac-refer-partner-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}

// Content
.ac-refer-partner-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-brand-secondary;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-refer-partner-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

.ac-refer-partner-body {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}

// Hero section
.ac-refer-partner-hero {
	background: url('../../assets/waves/wave-hero-purple.svg');
	background-position: 50% -200px;
	background-repeat: repeat-x;
	color: $ac-color-text-secondary;
	min-height: $ac-refer-partner-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;
}

.ac-refer-partner-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-refer-partner-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 300px;
	top: 70px;
	width: $ac-global-space-6xl;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl * 2; // 80px
		width: $ac-global-space-3xl * 2; // 80px
		right: 500px;
		top: 210px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// What makes our engineers unique section
.ac-refer-partner-engineers {
	background: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	min-height: $ac-refer-partner-engineers-section-height;
	padding: $ac-global-space-3xl $ac-global-space-xl;
	position: relative;
	width: 100%;
}

.ac-refer-partner-engineers-container {
	align-items: center;
	display: grid;
	gap: 125px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;

	.ac-refer-partner-subheading {
		text-align: left;
	}

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		gap: $ac-global-space-2xl;
	}
}

.ac-refer-partner-engineers-star {
	height: $ac-global-space-xl;
	left: 5%;
	opacity: $ac-global-opacity-more;
	position: absolute;
	top: 50px;
	width: $ac-global-space-xl;

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Our model section
.ac-refer-partner-model {
	background: $ac-color-gradient-light;
	display: flex;
	justify-content: center;
	margin-bottom: -72px;
	min-height: $ac-refer-partner-model-section-height;
	padding: $ac-refer-partner-wave-height $ac-global-space-xl;
	position: relative;
	width: 100%;
}

.ac-refer-partner-model-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;

	.ac-refer-partner-subheading {
		text-align: left;
	}

	li {
		display: inline-block;
		margin-right: $ac-global-space-4xl;

		&:last-of-type {
			margin-right: 0;
		}

		@media screen and ($ac-global-breakpoints-sm) {
			display: block;
			margin-bottom: $ac-global-space-xl;
			margin-right: 0;
		}
	}
}

.ac-refer-partner-mission-content {
	@media screen and ($ac-global-breakpoints-md) {
		grid-row: 2;
		margin-bottom: $ac-global-space-3xl;
	}
}

// Hear what engineers section
.ac-refer-partner-hear-engineers {
	background: url('../../assets/waves/wave-galaxy.png');
	background-position: 50% 0;
	background-size: auto 100%;
	min-height: 669px;
	padding: $ac-refer-partner-wave-height 0 $ac-global-space-5xl;
	position: relative;
	width: 100%;
	z-index: 1000;

	.ac-refer-partner-subheading {
		align-self: flex-start;
		color: $ac-color-text-secondary;
		display: flex;
		margin: 0 auto $ac-global-space-3xl;
		max-width: $ac-global-content-container-width;
		width: 100%;

		@media screen and ($ac-global-breakpoints-lg) {
			margin-bottom: $ac-global-space-2xl;
			padding: 0 $ac-global-space-xl;
		}
	}

	.slick-dots {
		bottom: -10px;
	}
}

// Become a referral partner section
.ac-refer-partner-become-partner {
	background: $ac-color-gradient-light;
	padding: $ac-global-space-5xl + $ac-global-space-sm $ac-global-space-xl; // 88px
	margin-bottom: -100px;
	margin-top: -$ac-global-space-5xl;
	position: relative;

	.ac-refer-partner-subheading {
		text-align: left;
	}
}

.ac-refer-partner-become-partner-form {
	background: $ac-color-neutral-0;
	border: 1px solid $ac-color-neutral-1;
	border-radius: $ac-global-border-radius-rounded-lg;
	margin: $ac-global-space-6xl auto;
	max-width: $ac-refer-partner-become-partner-form-width;
	padding: $ac-global-space-4xl;

	@media screen and ($ac-global-breakpoints-md) {
		padding: $ac-global-space-2xl;
	}
}

.ac-refer-partner-become-partner-form-column:nth-child(2) {
	display: flex;
	flex-direction: column;

	.ac-checkbox {
		flex: 1 1 auto;
		margin-top: $ac-global-space-xl;
	}

	.ac-textarea {
		flex: 1 1 $ac-refer-partner-become-partner-form-textarea-height;
	}

	.ac-button {
		align-self: flex-end;
	}
}

.ac-refer-partner-become-partner-form-container {
	display: grid;
	gap: $ac-global-space-4xl;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin-top: $ac-global-space-3xl;

	input {
		margin-bottom: $ac-global-space-lg;
	}

	@media screen and ($ac-global-breakpoints-md) {
		gap: $ac-global-space-lg;
		grid-template-columns: 1fr;

		> div:nth-child(2) {
			.ac-textarea {
				flex: 1 1 auto;
			}
		}
	}

	@media screen and ($ac-global-breakpoints-xs) {
		gap: 0px !important;
		grid-template-columns: repeat(1, 1fr) !important;

		> div:nth-child(2) {
			.ac-textarea {
				flex: unset  !important;
			}
		}

		> div:nth-child(2) {
			.ac-textarea .ac-textarea-field {
				width: 100% !important;
				min-width: 205px !important;
			}
		}
	}
}

@media screen and ($ac-global-breakpoints-xs) {
	.ac-refer-partner-become-partner-form-column {
		width: 100% !important;
	}

	.ac-refer-partner-become-partner-form-column .ac-input-field {
		width: 100% !important;
		min-width: 205px !important;
	}
}


.ac-refer-partner-become-partner-form-column:nth-child(2) {
	display: flex;
	flex-direction: column;

	.ac-checkbox {
		flex: 1 1 auto;
		margin-top: $ac-global-space-xl;
	}

	.ac-textarea {
		flex: 1 1 $ac-refer-partner-become-partner-form-textarea-height;
	}

	.ac-button {
		align-self: flex-end;
	}
}

.ac-refer-partner-become-partner-star {
	height: 155px;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 5%;
	top: 125px;
	width: 155px;

	&:nth-of-type(2) {
		left: 6%;
		height: $ac-global-space-4xl;
		width: $ac-global-space-4xl;
		top: 300px;
	}

	&:nth-of-type(3) {
		bottom: 100px;
		height: $ac-global-space-5xl;
		right: 10%;
		top: auto;
		width: $ac-global-space-5xl;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}

// Make an impact section
.ac-refer-partner-impact {
	background: url('../../assets/waves/wave-actions-purple.svg');
	background-position: 50% 0;
	background-repeat: repeat-x;
	color: $ac-color-neutral-0;
	display: flex;
	justify-content: center;
	min-height: $ac-refer-partner-impact-section-height;
	padding: $ac-refer-partner-wave-height $ac-global-space-xl
		$ac-global-space-4xl;
	position: relative;
	width: 100%;

	@media screen and ($ac-global-breakpoints-xs) {
		background-size: auto 100% !important;
	}

	h2 {
		margin-bottom: $ac-global-space-3xl + $ac-global-space-sm; // 48px
	}

	// Fontawesome icon
	svg {
		margin: 0 auto $ac-global-space-2xl;
		font-size: $ac-global-space-4xl;
	}
}

.ac-refer-partner-impact-container {
	margin: 0 auto;
	max-width: 1030px;
	width: 100%;
	position: relative;
}

.ac-refer-partner-impact-content {
	display: grid;
	grid-template-columns: repeat(2, minmax(360px, 1fr));
	text-align: center;
	gap: 211px;

	@media screen and ($ac-global-breakpoints-md) {
		grid-template-columns: 1fr;
		gap: 72px;
	}
}
