// AnnieCannons

// Styles / Mixins

// Outputs CSS for xl heading text
@mixin ac-mx-heading-text-xl {
	font-family: $ac-global-font-family-bold;
	font-size: $ac-global-font-size-2xl;
	letter-spacing: $ac-global-letter-spacing-narrow;
	line-height: $ac-global-line-height-xs;
}

// Outputs CSS for heading text
@mixin ac-mx-heading-text {
	font-family: $ac-global-font-family-bold;
	font-size: $ac-global-font-size-xl;
	letter-spacing: $ac-global-letter-spacing-narrow;
	line-height: $ac-global-line-height-sm;
}

// Outputs CSS for subheading text
@mixin ac-mx-subheading-text {
	font-family: $ac-global-font-family-bold;
	font-size: $ac-global-font-size-lg;
	letter-spacing: 0;
	line-height: $ac-global-line-height-sm;
}

// Outputs CSS for body text
@mixin ac-mx-body-text {
	font-family: $ac-global-font-family;
	font-size: $ac-global-font-size-sm;
	letter-spacing: $ac-global-letter-spacing-wide;
	line-height: $ac-global-line-height-md;
}

// Outputs CSS for button text
@mixin ac-mx-button-text {
	font-family: $ac-global-font-family-bold;
	font-size: $ac-global-font-size-xs;
	letter-spacing: $ac-global-letter-spacing-wider;
	text-transform: uppercase;
}

// Outputs CSS for link text
@mixin ac-mx-link-text {
	font-family: $ac-global-font-family-semibold;
	font-size: $ac-global-font-size-sm;
	letter-spacing: $ac-global-letter-spacing-wide;
}

// Outputs CSS for form label text
@mixin ac-mx-label-text {
	color: $ac-color-text-primary;
	font-family: $ac-global-font-family-bold;
	font-size: $ac-global-font-size-xs;
	letter-spacing: $ac-global-letter-spacing-wide;
}

// Outputs CSS for .ac-button.-primary
@mixin ac-mx-button-primary {
	background-color: $ac-color-teal-1;
	border-color: $ac-color-teal-1;
	color: $ac-color-text-secondary;

	// Pressed
	&:active {
		background-color: $ac-color-button-primary-active;
	}

	// Disabled
	&:disabled,
	&.is-disabled {
		background-color: $ac-color-teal-4;
		border-color: $ac-color-teal-4;
		box-shadow: none;
	}

	// Hover
	&:hover {
		box-shadow: $ac-global-box-shadow;
	}

	&:hover:disabled,
	&:hover.is-disabled {
		box-shadow: none;
	}

	// Focus
	&:focus {
		outline: none;
		box-shadow: $ac-global-button-focus-ring-thick;
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
	}
}

// Outputs CSS for .ac-button.-secondary
@mixin ac-mx-button-secondary {
	background-color: transparent;
	border-color: $ac-color-neutral-4;
	color: $ac-color-text-primary;

	// Active
	&:active {
		background-color: $ac-color-neutral-4;
	}

	// Disabled
	&:disabled,
	&.is-disabled {
		border-color: $ac-color-neutral-2;
		color: $ac-color-neutral-2;
	}

	// Hover
	&:hover {
		background-color: $ac-color-neutral-4;
		box-shadow: $ac-global-box-shadow;
		color: $ac-color-text-secondary;
	}

	// Focus
	&:focus {
		background-color: $ac-color-neutral-4;
		color: $ac-color-text-secondary;
	}
}

// Outputs CSS for .ac-button.-secondary.-dark-mode
@mixin ac-mx-button-secondary-dark-mode {
	&.-dark-mode {
		border-color: $ac-color-neutral-0;
		color: $ac-color-text-secondary;

		&:hover {
			background-color: $ac-color-neutral-0;
			box-shadow: none;
			color: $ac-color-text-primary;
		}

		&:focus {
			background-color: $ac-color-neutral-0;
			box-shadow: $ac-global-button-focus-ring-thin-inverted;
			color: $ac-color-text-primary;
		}

		&:focus:not(:focus-visible) {
			box-shadow: none;
		}
	}
}

// Outputs CSS for .ac-button.-bare
@mixin ac-mx-button-bare {
	background-color: transparent;
	border-color: transparent;
	color: $ac-color-text-primary;

	&:hover {
		svg {
			transform: translateX(8px);
		}
	}

	// Fontawesome Icon
	svg {
		color: $ac-color-brand-primary;
		margin-left: $ac-global-space-sm;
		transition: all $ac-transition-time-normal $ac-transition-link;
	}

	// Disabled
	&:disabled,
	&.is-disabled {
		color: $ac-color-neutral-2;

		// Fontawesome Icon
		svg {
			color: $ac-color-teal-4;
		}
	}

	// Focus
	&:focus {
		box-shadow: $ac-global-button-focus-ring-thin;
		outline: none;
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
	}
}

// Outputs CSS for .ac-button.-bare.-dark-mode
@mixin ac-mx-button-bare-dark-mode {
	&.-dark-mode {
		color: $ac-color-text-secondary;

		// Fontawesome Icon
		svg {
			color: $ac-color-neutral-0;
		}

		// Disabled
		&:disabled,
		&.is-disabled {
			color: $ac-color-neutral-2;

			// Fontawesome Icon
			svg {
				color: $ac-color-neutral-2;
			}
		}

		&:focus {
			box-shadow: $ac-global-button-focus-ring-thick-inverted;
		}

		&:focus:not(:focus-visible) {
			box-shadow: none;
		}
	}
}

// Outputs CSS for .ac-button.-nav
@mixin ac-mx-button-nav {
	background-color: $ac-color-neutral-0;
	border-color: transparent;
	border-radius: 0;
	color: $ac-color-text-primary;
	padding: $ac-global-space-3xl $ac-global-space-lg;
	position: relative;

	// Hover
	&:hover {
		background-color: rgba($ac-color-purple-3, 0.2);
		color: $ac-color-purple-1;
	}

	// Focus
	&:focus {
		box-shadow: $ac-global-button-focus-ring-thin;
		outline: none;
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
	}

	&.is-selected {
		color: $ac-color-purple-1;

		&::after {
			border-block-end: 3px solid $ac-color-purple-1;
			bottom: $ac-global-space-lg;
			content: '';
			height: 1px;
			left: $ac-global-space-lg;
			position: absolute;
			width: calc(100% - 32px);
		}
	}
}

// Outputs CSS for .ac-button.-icon
@mixin ac-mx-button-icon {
	background-color: $ac-color-brand-secondary;
	border-color: $ac-color-brand-secondary;
	border-radius: $ac-global-border-radius-circle;
	color: $ac-color-neutral-0;
	font-size: $ac-global-font-size-md;
	height: 60px;
	width: 60px;
	padding: 0;

	// Hover
	&:hover {
		box-shadow: $ac-global-box-shadow;
	}

	// Focus
	&:focus {
		outline: none;
		box-shadow: $ac-global-button-focus-ring-thick;
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
	}
}
