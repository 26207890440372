@import '../../styles/_index.scss';

// Local Variables
$ac-consult-wave-height: 136px; // See svg files for height
$ac-consult-hero-section-height: 400px;
$ac-request-consult-form-width: 857px;
$ac-request-consult-form-textarea-height: 224px;
$ac-volunteer-impact-section-height: 320px;
$ac-home-top-funders-section-height: 325px;

// Wave container
.ac-volunteer-wave {
	background-repeat: repeat-x;
	height: $ac-consult-wave-height;
	position: absolute;
	width: 100%;
	z-index: 1;
}

// Content
.ac-consult-heading {
	@include ac-mx-heading-text-xl;
	font-size: $ac-global-font-size-2xl;
	margin-bottom: $ac-global-space-xl;
	padding-top: $ac-global-space-6xl;

	span {
		color: $ac-color-brand-secondary;
	}

	@media screen and ($ac-global-breakpoints-md) {
		font-size: $ac-global-font-size-xl;
		margin-bottom: $ac-global-space-lg;
		margin-top: 0;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-lg;
	}
}

.ac-volunteer-subheading {
	@include ac-mx-subheading-text;
	margin-bottom: $ac-global-space-xl;
	text-align: center;

	@media screen and ($ac-global-breakpoints-sm) {
		font-size: $ac-global-font-size-md;
		margin-bottom: $ac-global-space-md;
	}
}

.ac-volunteer-body {
	@include ac-mx-body-text;
	margin-bottom: $ac-global-space-2xl;
	line-height: $ac-global-line-height-md;
}

// Hero section
.ac-consult-hero {
	z-index: 2;
	background: url('../../assets/waves/wave-hero-purple.svg');
	background-position: 50% bottom;
	background-repeat: no-repeat;
	color: $ac-color-text-secondary;
	min-height: $ac-consult-hero-section-height;
	padding: 0 $ac-global-space-xl;
	position: relative;


	@media screen and ($ac-global-breakpoints-xs) {
		min-height: 330px !important;
	}
}

.ac-consult-hero-container {
	margin: 0 auto;
	max-width: $ac-global-content-container-width;
	position: relative;
	width: 100%;
}

.ac-consult-hero-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-less;
	position: absolute;
	right: 200px;
	top: 70px;
	width: $ac-global-space-6xl;

	> g {
		fill: $ac-color-purple-3;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-3xl * 2; 
		width: $ac-global-space-3xl * 2; 
		right: 425px;
		top: 290px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}


// Request a consult
.ac-request-consult {
	background: $ac-color-gradient-light;
	padding: $ac-global-space-7xl $ac-global-space-xl $ac-global-space-6xl;
	margin-top: -$ac-consult-wave-height;
	position: relative;

	.ac-volunteer-subheading {
		text-align: left;
	}
}

.ac-request-consult-inner {
	max-width: $ac-request-consult-form-width;
	margin: auto;
}

.ac-request-consult-form {
	background: $ac-color-neutral-0;
	border: 1px solid $ac-color-neutral-1;
	border-radius: $ac-global-border-radius-rounded-lg;
	margin: $ac-global-space-4xl auto $ac-global-space-6xl;
	max-width: $ac-request-consult-form-width;
	padding: $ac-global-space-4xl;

	@media screen and ($ac-global-breakpoints-md) {
		padding: $ac-global-space-2xl;
	}
}

.ac-request-consult-form-container {
	display: grid;
	gap: $ac-global-space-4xl;
	grid-template-columns: repeat(2, minmax(0, 1fr));

	input {
		margin-bottom: $ac-global-space-lg;
	}

	@media screen and ($ac-global-breakpoints-md) {
		gap: $ac-global-space-lg;
		grid-template-columns: 1fr;

		> div:nth-child(2) {
			.ac-textarea {
				flex: 1 1 auto;
			}
		}
	} 

	@media screen and ($ac-global-breakpoints-xs) {
		gap: 0px !important;
		grid-template-columns: repeat(1, 1fr) !important;

		> div:nth-child(2) {
			.ac-textarea {
				flex: unset  !important;
			}
		}

		> div:nth-child(2) {
			.ac-textarea .ac-textarea-field {
				width: 100% !important;
				min-width: 205px !important;
			}
		}
	}
}


@media screen and ($ac-global-breakpoints-xs) {
	.ac-request-consult-form-column {
		width: 100% !important;
	}

	.ac-request-consult-form-column .ac-input-field {
		width: 100% !important;
		min-width: 205px !important;
	}
}


.ac-request-consult-form-column:nth-child(2) {
	display: flex;
	flex-direction: column;

	.ac-checkbox {
		flex: 1 1 auto;
		margin-top: $ac-global-space-xl;
	}

	.ac-textarea {
		flex: 1 1 $ac-request-consult-form-textarea-height;
	}

	.ac-button {
		align-self: flex-end;
	}
}



.ac-request-consult-star {
	height: $ac-global-space-6xl;
	opacity: $ac-global-opacity-most;
	position: absolute;
	right: -19%;
	bottom: -20px;
	width: $ac-global-space-xl;

	> g {
		fill: $ac-color-purple-1;
	}

	&:nth-of-type(2) {
		height: $ac-global-space-xl * 2; 
		width: $ac-global-space-xl * 2; 
		right: -19%;
		bottom: -60px;
	}

	&:nth-of-type(3) {
		height: $ac-global-space-6xl; 
		width: $ac-global-space-6xl; 
		right: -16%;
		bottom: -220px;
	}

	@media screen and ($ac-global-breakpoints-md) {
		display: none;
	}
}


// Our clients

.ac-consult-clients-content {
    display: grid;
	align-items: center;
    gap: 80px 100px;
	grid-template-columns: repeat(3, 1fr);
	
	.ac-consult-clients-logos {
		width: 100%;
		height: auto;
		margin: auto;
	}

	@media screen and ($ac-global-breakpoints-md) {
		// gap: $ac-global-space-5xl !important;
		grid-template-columns: repeat(2, 1fr) !important;

		.ac-consult-clients-logos {
			width: 70% !important;
		}
	}

	@media screen and ($ac-global-breakpoints-xs) {
		// gap: $ac-global-space-3xl + $ac-global-space-sm !important;
		grid-template-columns: repeat(1, 1fr) !important;
	}
}


