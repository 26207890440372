@import './_index.scss';

// Local variables
$ac-button-height: 50px;

.ac-button {
	@include ac-mx-button-text;
	align-items: center;
	border-radius: $ac-global-border-radius-rounded-lg;
	border-style: solid;
	border-width: $ac-global-border-width-thick;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	height: $ac-button-height;
	padding: 0 $ac-global-space-2xl;
	position: relative;
	text-decoration: none;
	transition: background $ac-transition-time-normal $ac-transition-link,
		border $ac-transition-time-normal $ac-transition-link,
		box-shadow $ac-transition-time-normal $ac-transition-link,
		color $ac-transition-time-normal $ac-transition-link;
	white-space: nowrap;

	// Active, Visited (links)
	&:active,
	&:visited {
		color: currentColor;
	}

	// Focus
	&:focus {
		outline: none;
		box-shadow: $ac-global-button-focus-ring-thick;
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
		border-bottom: none;
	}

	// Disabled
	&:disabled,
	&.is-disabled {
		pointer-events: none;
	}

	@media screen and ($ac-global-breakpoints-sm) {
		height: $ac-global-space-3xl;
		font-size: $ac-global-space-md;
		padding: 0 $ac-global-space-xl;
	}

	// Button Variants
	&.-no-padding {
		padding: 0;
	}

	&.-primary {
		@include ac-mx-button-primary;
	}

	&.-secondary {
		@include ac-mx-button-secondary;
	}

	&.-secondary.-dark-mode {
		@include ac-mx-button-secondary-dark-mode;
	}

	&.-bare {
		@include ac-mx-button-bare;
	}

	&.-bare.-dark-mode {
		@include ac-mx-button-bare-dark-mode;
	}

	&.-nav {
		@include ac-mx-button-nav;
	}

	&.-icon {
		@include ac-mx-button-icon;
	}

	&.has-arrow:not(.-no-icon) {
		display: inline-grid;
		grid-template-areas: 'text arrow';
		grid-template-columns: 1fr min-content;
		grid-template-rows: 1fr;

		&.-dark-mode {
			&::before {
				background-color: $ac-color-text-secondary;
			}
		}

		> span {
			grid-area: text;
		}

		> svg {
			grid-area: arrow;
		}

		&::before {
			background-color: $ac-color-brand-primary;
			content: '';
			grid-area: arrow;
			height: 2px;
			margin-left: $ac-global-space-sm;
			width: 10px;
		}
	}

	// Note this is only for the arrow svg found on the Home page
	.ac-home-arrow-default {
		transform: rotate(90deg);

		g {
			stroke: $ac-color-neutral-0;
		}
	}
}
